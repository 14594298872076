import create from 'zustand'
import getStore, {
  ITransactionsState, ITransactionsStore
} from '@store/transactions'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

export const initTransactionsStore = (preloadedState?: ITransactionsState) => {
  return create<ITransactionsStore>(getStore(preloadedState))
}

export const useCreateTransactionsStore = (initialState?: ITransactionsState) => {
  const state =
    typeof initialState === 'string' ? JSON.parse(initialState) : initialState
  const _store = initTransactionsStore(state)

  useIsomorphicLayoutEffect(() => {
    _store.getState().hydrate(state)
  }, [ _store, state ])

  return _store
}

export default useCreateTransactionsStore
