import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const StyledToastContainer = styled(ToastContainer)`
&&&.Toastify__toast-container {
  width: auto;
  margin-left: 1em;
}

.Toastify__toast {
  margin-bottom: 1em;
  border-left: 0.5rem solid ${({ theme }) => theme.COLOR.PRIMARY_BLACK};
  color: ${({ theme }) => theme.COLOR.PRIMARY_BLACK};
  background-color: ${({ theme }) => theme.COLOR.PRIMARY_BLACK_TRANSPARENT};
  border-radius: 0;
  padding: 16px 24px 16px 25px;
}

.Toastify__close-button {
  color: ${({ theme }) => theme.COLOR.PRIMARY_BLACK} !important;
  opacity: 1;
  svg {
    width: 16px !important
  }

  &:hover {
    opacity: 0.6;
  }
}

.Toastify__toast-body {
  padding: 0;
}

// Info Toast
.Toastify__toast--info {
  border-color: ${({ theme }) => theme.COLOR.PRIMARY_DARKBLUE};
  background-color: ${({ theme }) => theme.COLOR.PRIMARY_DARKBLUE_TRANSPARENT};
}
.Toastify__toast--info .Toastify__close-button {
  color: ${({ theme }) => theme.COLOR.PRIMARY_DARKBLUE} !important;
}

// Info Success
.Toastify__toast--success {
  border-color: ${({ theme }) => theme.COLOR.SUPPORT_VALID};
  background-color: ${({ theme }) => theme.COLOR.SUPPORT_VALID_TRANSPARENT};
}
.Toastify__toast--success .Toastify__close-button {
  color: ${({ theme }) => theme.COLOR.SUPPORT_VALID} !important;
}

// Info Error
.Toastify__toast--error {
  border-color: ${({ theme }) => theme.COLOR.SUPPORT_INVALID};
  background-color: ${({ theme }) => theme.COLOR.SUPPORT_INVALID_TRANSPARENT};
}
.Toastify__toast--error .Toastify__close-button {
  color: ${({ theme }) => theme.COLOR.SUPPORT_INVALID} !important;
}

@media (min-width: ${({ theme }) =>
theme.LAYOUT.BREAKPOINT.SM}px) {
  &&&.Toastify__toast-container {
    width: 500px;
    margin-left: 0;
    top: unset;
    left: unset;
    transform: unset;
  }
}
`
