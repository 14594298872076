import { MouseEvent } from 'react'
import IconNames from '@components/icon/icon-names'
import Link from '@components/link'
import { ILink } from '@utils/types'
import {
  StyledContainer,
  StyledIcon
} from './style'

export interface ITextLinkProps {
  label?: string
  icon?: IconNames
  link?: ILink,
  className?: string
  onClick?: (e: MouseEvent) => void
  isInline?: boolean
  isInverted?: boolean
  isSecondary?: boolean
  isDisabled?: boolean
}

const TextLink = ({
  label,
  icon,
  link,
  isInline,
  isInverted,
  isSecondary,
  isDisabled,
  ...props
} : ITextLinkProps) : JSX.Element => {
  const Component = link ? Link : 'button'
  return (
    <StyledContainer
      as={Component}
      type="button"
      disabled={isDisabled}
      $isInline={isInline}
      $isInverted={isInverted}
      $isSecondary={isSecondary}
      $isDisabled={isDisabled}
      {...{
        link, ...props
      }}
    >
      {icon && !isInline && <StyledIcon name={icon} />}
      {label}
    </StyledContainer>
  )
}

export default TextLink
