import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import {
  useContext
} from 'react'
import { StoreContext } from '@utils/zustand/store-provider'
import {
  ILicensePlate,
  ILicensePlatesStore
} from '@store/license-plates'
import { fullState } from '@store/license-plates/selectors'
import { initLicensePlatesStore } from '@hooks/use-create-license-plates-store'

const useLicensePlatesStore = () => {
  const storeFromContext = useContext(StoreContext)?.licensePlates
  const store = storeFromContext || initLicensePlatesStore()
  const autoGeneratedSelectorHooks = createSelectorHooks<ILicensePlatesStore>(store)

  const useLicensePlatesByReportCode = (reportCodeInput: number|undefined): ILicensePlate[] | undefined =>
    autoGeneratedSelectorHooks().permitsLicensePlates.find(({ permitReportCode }) => permitReportCode === reportCodeInput)?.licensePlates

  return {
    ...autoGeneratedSelectorHooks,
    fullState: store(fullState),
    useLicensePlatesByReportCode,
  }
}

export default useLicensePlatesStore
