enum IconNames {
  ALERT = 'alert',
  ANDREAS = 'andreas',
  CAR = 'car',
  CHECKMARK = 'checkmark',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CLOSE = 'close',
  CLOSE_CIRCLE = 'close-circle',
  EDIT_DOCUMENT = 'edit-document',
  ENLARGE = 'enlarge',
  EUROCOIN = 'eurocoin',
  EXTERNAL_LINK = 'external-link',
  INFO = 'info',
  LOADER = 'loader',
  LOGOUT = 'logout',
  MAP = 'map',
  MENU = 'menu',
  PERSONAL_LOGIN = 'personal-login',
  SEARCH = 'search',
  SHARE = 'share',
  TRASH_BIN = 'trash-bin',
}

export default IconNames
