import {
  ElementType,
  ReactNode,
  RefObject
} from 'react'
import { HeadingTypes } from '@theme/basic/typography'
import StyledHeading from './style'

export interface IHeadingProps {
  type?: HeadingTypes
  children?: ReactNode
  level?: HeadingTypes
  ref?: RefObject<HTMLElement>
  id?:string
}

const defaultAs: {[key:string]: ElementType} = {
  [HeadingTypes.HEADING_1]: 'h1',
  [HeadingTypes.HEADING_2]: 'h2',
  [HeadingTypes.HEADING_3]: 'h3',
  [HeadingTypes.HEADING_4]: 'h4',
}

function Heading ({
  type = HeadingTypes.HEADING_1,
  ref,
  children,
  level,
  ...props
}: IHeadingProps) {
  return (
    <StyledHeading ref={ref} as={level ? defaultAs[level] : defaultAs[type]} $type={type} {...props }>
      {children}
    </StyledHeading>
  )
}

export default Heading
