import { ReactNode, useContext } from 'react'
import { useTheme } from 'styled-components'
import { BreakPoints, BreakPointEntries } from '@utils/types'
import { StyledColumn } from './style'
import ColumnContext, { IColumnContext } from './ColumnContext'

export {
  Container, Row
} from './style'

const calculateMaximumContainerWidth = (
  nextBreakpointValue: number,
  maxContainerWidth: number,
  layoutGridOffset: number
) => Math.min(maxContainerWidth, (nextBreakpointValue || maxContainerWidth) - layoutGridOffset * 2)

const getNextBreakpointWidth = (breakpointsEntries: BreakPointEntries, idx: number) => {
  const nextBreakpoint = breakpointsEntries[idx + 1]
  return nextBreakpoint && nextBreakpoint[1] - 1
}

const calculateMaximumColumnWidth = (
  maximumContainerWidth: number,
  gridGap: number,
  totalColumns: number,
  columns: number
) => Math.floor(((maximumContainerWidth + gridGap) / totalColumns) * columns - gridGap)

interface Props {
  columns: BreakPoints
  offset?: BreakPoints
  children: ReactNode
  className?: string
}

export function Column ({
  columns = {
    XXS: 12,
    XS: 12,
    SM: 12,
    MD: 12,
    LG: 12,
  },
  offset = {},
  children,
  className
}: Props) {
  const columnContext = useContext(ColumnContext)

  const {
    LAYOUT: {
      BREAKPOINT,
      MAX_CONTAINER_WIDTH,
      GRID_OFFSET,
      GRID_GAP,
      GRID_COLUMNS,
    },
  } = useTheme()

  const breakpointsEntries: BreakPointEntries = Object.entries(
    BREAKPOINT
  ) as BreakPointEntries

  const reducedColumns: BreakPoints = Object.keys(BREAKPOINT).reduce(
    (accumulator, key, idx) => {
      return {
        ...accumulator,
        [key]:
        columns[key as keyof BreakPoints] ||
        Object.values(accumulator)[idx - 1] ||
        GRID_COLUMNS,
      }
    },
    {}
  ) as BreakPoints

  const columnContextValue: IColumnContext = breakpointsEntries.reduce(
    (accumulator, [ key, value ], idx) => {
      const nextBreakpointWidth = getNextBreakpointWidth(breakpointsEntries, idx)
      const maximumContainerWidth =
        (columnContext && columnContext[key]?.maximumColumnWidth) ||
        calculateMaximumContainerWidth(
          nextBreakpointWidth,
          MAX_CONTAINER_WIDTH,
          GRID_OFFSET[key as keyof BreakPoints]
        )
      const maximumColumnWidth = calculateMaximumColumnWidth(
        maximumContainerWidth,
        GRID_GAP[key],
        GRID_COLUMNS,
        reducedColumns[key] || 0
      )

      return {
        ...accumulator,
        [key]: {
          breakpointValue: value,
          nextBreakpointWidth,
          maximumColumnWidth,
        },
      }
    },
    {}
  )

  return (
    <ColumnContext.Provider value={columnContextValue}>
      <StyledColumn $columns={columns} $columnOffset={offset} children={children} className={className} />
    </ColumnContext.Provider>
  )
}
