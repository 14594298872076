import create from 'zustand/vanilla'
import { devtools, persist } from 'zustand/middleware'
import { WritableDraft } from 'immer/dist/internal'
import immer from '@utils/zustand/immer-middleware'
import { StoreApi } from 'zustand'
import { ITransactionMeta } from '@store/transactions'

export const STORE_NAME = 'parking-sessions'

type StoreSet = (fn: (draft: WritableDraft<IParkingSessionsStore>) => void) => void;
export interface IPrice {
  currency: string
  value: number
}

export interface IParkingSessionsMeta {
  totalItems: number
  itemsPerPage: number
  pages: number
  currentPage: number
  pagination: {
    first: string
    prev?: string
    curr: string
    next?: string
    last: string
  }
}

export interface IParkingSession {
  psRightId: number,
  vehicleId: string,
  status: string,
  startDate: string,
  endDate: string,
  remainingTime: number,
  visitorName?: string,
  reportCode: number,
  createdTime?: string,
  parkingTime?: string,
  parkingCost?: IPrice
  isCancelled?: boolean
  paymentZoneId: string
  isPaid: boolean
}

export interface IParkingSessionsState {
  parkingSessions: IParkingSession[]
  totalPlannedParkingSessions: number,
  totalActiveParkingSessions: number,
  meta: IParkingSessionsMeta
  visitorValidatedVehicleId: string
}

export interface IParkingSessionsStore extends IParkingSessionsState {
  setParkingSessions: (parkingSessions: IParkingSession[]) => void,
  setTotals: (totalPlannedParkingSessions: number, totalActiveParkingSessions:number) => void,
  setMeta: (meta: ITransactionMeta) => void,
  hydrate: (input: IParkingSessionsState) => void,
  clear: () => void,
  setVisitorValidatedVehicleId: (visitorValidatedVehicleId: string) => void,
}

let store: StoreApi<IParkingSessionsStore> | undefined

const initialState:IParkingSessionsState = {
  parkingSessions: [],
  totalActiveParkingSessions: 0,
  totalPlannedParkingSessions: 0,
  meta: {
    currentPage: 1,
    itemsPerPage: 0,
    pages: 0,
    totalItems: 0,
    pagination: {
      curr: '',
      first: '',
      last: '',
      next: '',
      prev: ''
    }
  },
  visitorValidatedVehicleId: ''
}

const createState = (preLoadedState?: IParkingSessionsState) => (set: StoreSet) => ({
  ...initialState,
  ...preLoadedState,
  setParkingSessions: (parkingSessions: IParkingSession[]) => set((state) => {
    state.parkingSessions = Array.isArray(parkingSessions) && parkingSessions?.length > 0 ? parkingSessions : []
  }),
  setTotals: (totalPlannedParkingSessions: number, totalActiveParkingSessions:number) => set((state) => {
    state.totalPlannedParkingSessions = totalPlannedParkingSessions
    state.totalActiveParkingSessions = totalActiveParkingSessions
  }),
  setMeta: (meta: ITransactionMeta) =>
    set((state) => {
      state.meta = meta
    }),
  setVisitorValidatedVehicleId: (visitorValidatedVehicleId: string) => set((state) => {
    state.visitorValidatedVehicleId = visitorValidatedVehicleId
  }),
  hydrate: (input: IParkingSessionsState) => set((state: IParkingSessionsState) => {
    if (state && input) {
      const newState = {
        ...state,
        parkingSessions: input.parkingSessions || state.parkingSessions || [],
        meta: input.meta || state.meta,
        totalActiveParkingSessions: input.totalActiveParkingSessions || state.totalActiveParkingSessions || 0,
        totalPlannedParkingSessions: input.totalPlannedParkingSessions || state.totalPlannedParkingSessions || 0,
        visitorValidatedVehicleId: input.visitorValidatedVehicleId || state.visitorValidatedVehicleId || ''
      }

      state = newState

      return state
    } else {
      return state
    }
  }),
  clear: () => set(() => {
    return initialState
  }),
})

const getStore = (preLoadedState?:IParkingSessionsState) => {
  if (process.browser && store) {
    if (preLoadedState) {
      store.getState().hydrate(preLoadedState)
    }
    return store
  }

  store = create<IParkingSessionsStore>(
    persist(
      devtools(
        immer(createState(preLoadedState)), { name: STORE_NAME }
      ), { name: STORE_NAME, getStorage: () => sessionStorage })
  )

  return store
}

export default getStore
