enum pages {
  SITE_ROOT = '/',
  LOGIN = '/login',
  REQUEST_PIN = '/pincode',
  PARKING_SESSIONS = '/parking-sessions',
  NEW_SESSION = '/parking-sessions/new',
  CONFIRM = '/parking-sessions/success',
  BALANCE_TOP_UP = '/top-up-balance',
  VISITOR_ACCOUNT_SETTINGS = '/visitor-account-settings',
  FAVORITES = '/favorites',
  TRANSACTIONS = '/transactions',
  ACCOUNT_SETTINGS='/account-settings',
  UNSUPPORTED_REGION='/unsupported-region',
  API_ORDERS='/api/orders'
}

export default pages
