export const nl = {
  'seo-title': '{page} - Parkeerapplicatie',
  'seo-description': 'Parkeerapplicatie',
  'page.unsupported-region': 'Niet ondersteunde regio',
  'page.login': 'Inloggen',
  'page.pincode': 'Pincode',
  'page.root': 'Overzicht',
  'page.transactions': 'Historie',
  'page.visitor.account': 'Bezoekersaccount',
  'page.favorites': 'Favorieten',
  'page.new.parking.session': 'Nieuwe parkeersessie',
  'page.account.settings': 'Instellingen',
  'page.balance.top.up': 'Saldo opwaarderen',
  'page.parking-sessions.active': 'Actieve sessies',
  'page.parking-sessions.planned': 'Geplande sessies',
  'page.parking-sessions.success': 'Nieuwe parkeersessie successvol',
  'menu.open.aria-label': 'Open hoofdmenu',
  'menu.close.aria-label': 'Sluit hoofdmenu',
  'menu.sign-out': 'Afmelden',
  'skip-link.label': 'Naar hoofdinhoud',
  back: 'Terug',
  next: 'volgende',
  'offline-title': 'Systeem is op dit moment offline.',
  'offline-message': 'Probeer het later opnieuw.',
  '404.intro.title': 'Pagina niet gevonden',
  '404.intro.subtitle': 'Deze pagina is jammer genoeg niet beschikbaar',
  '404.intro.cta.label': 'Terug naar home',
  'unsupported-region.intro.title': 'U bevindt zich buiten de EU',
  'unsupported-region.intro.subtitle': 'Dit portaal is jammer genoeg niet beschikbaar buiten de EU',
  'minute.long': '{value} minuut',
  'minutes.long': '{value} minuten',
  'hour.long': '{value} uur',
  'hours.long': '{value} uur',
  'minute.short': '{value} min',
  'minutes.short': '{value} min',
  'hour.short': '{value} uur',
  'hours.short': '{value} uur',
  'hours.label': 'uur',
  'time.today': 'Vandaag',
  'time.tomorrow': 'Morgen',
  and: 'en',
  warning: 'Let op!',
  'header.title': 'Aanmelden parkeren',
  'header.switch-language': 'English',
  'footer.contact.title': 'Contact',
  'footer.contact.content.1':
    'Heeft u een vraag en kunt u het antwoord niet vinden op {link}? Neem dan contact met ons op',
  'footer.contact.content.1.link.label':
    'amsterdam.nl/parkeren',
  'footer.contact.content.1.link.href':
    'https://www.amsterdam.nl/parkeren-verkeer/',
  'footer.contact.content.2':
    'Bel het telefoonnummer 14 020 maandag tot en met vrijdag van 08.00 tot 18.00 uur',
  'footer.contact.link.label.1': 'Contactformulier',
  'footer.contact.link.label.2': 'Contactgegevens en openingstijden',
  'footer.contact.link.url.1':
    'https://formulieren.amsterdam.nl/tripleforms/DirectRegelen/formulier/nl-NL/evAmsterdam/Klachtenformulier.aspx',
  'footer.contact.link.url.2': 'https://www.amsterdam.nl/contact/',
  'footer.bottom.link.label.1': 'Over deze site',
  'footer.bottom.link.label.2': 'Privacy',
  'footer.bottom.link.label.3': 'Webarchief',
  'footer.bottom.link.url.1': 'https://www.amsterdam.nl/overdezesite/',
  'footer.bottom.link.url.2': 'https://www.amsterdam.nl/privacy/',
  'footer.bottom.link.url.3': 'https://amsterdam.archiefweb.eu/',
  'progress.indicator.previous': 'Voltooid:',
  'progress.indicator.current': 'Huidige:',
  'progress.indicator.next': 'Onvoltooid:',
  permits: 'Uw vergunningen',
  'permits.filter-buttons.description': 'Welke vergunning wilt u bekijken?',
  'permits.filter-buttons.all': 'Alle',
  'permit.home.label': 'parkeervergunning',
  'permit.home.link': 'http://amsterdam.nl/parkeren',
  'permit.information.title': 'Informatie over uw vergunning',
  'permit.permit-area': 'Uw vergunninggebied',
  'permit.price-per-hour': 'Uw parkeertarief',
  'permit.view-permit-area-link.label': 'Vergunninggebied bekijken',
  'permit.view-permit-area-link.url': 'https://www.amsterdam.nl/veelgevraagd/?productid=%7BDE09EE16-EC8F-4740-B007-1CF062E08D5E%7D',
  'permit.visitor-account.title': 'Bezoekersaccount',
  'permit.visitor-account.login': 'Inloggegevens',
  'permit.visitor-account.login.report-code': 'Meldcode',
  'permit.visitor-account.login.pin-code': 'Pin',
  'permit.visitor-account.login.copy': 'Inloggegevens kopiëren',
  'permit.visitor-account.login.copied':
    'Inloggegevens succesvol gekopiëerd naar het klembord',
  'permit.visitor-account.time-balance.title': 'Toegekend tijdsaldo',
  'permit.visitor-account.login.description':
    'Uw bezoek kan inloggen op {link}',
  'permit.visitor-account.login.link.href':
    'https://aanmeldenparkeren.amsterdam.nl',
  'permit.visitor-account.login.link.label': 'aanmeldenparkeren.amsterdam.nl',
  'permit.visitor-account.page.link.label': 'Beheren',
  'login.title':
    'Welkom op het Aanmelden Parkeren portaal van de Gemeente Amsterdam',
  'login.description':
    'Hier kunt u uw parkeersessie voor uw {link} beheren.',
  'login.description.2': 'U meldt zich aan met uw meldcode en pincode.',
  'login.form.reportCode.label': 'Meldcode',
  'login.form.reportCode.placeholder': 'Voer uw meldcode in',
  'login.form.pinCode.label': 'Pincode',
  'login.form.pinCode.placeholder': 'Voer uw pincode in',
  'login.form.pinCode.show.label': 'Pincode tonen',
  'login.form.pinCode.hide.label': 'Pincode verbergen',
  'login.button.label': 'Inloggen',
  'login.forgotCode.description': 'Meldcode of pincode vergeten?',
  'login.errorMessage.title': 'Inloggegevens komen niet overeen',
  'login.errorMessage.body':
    'Check uw meldcode en pincode en probeer het opnieuw.',
  'login.getInTouch.label': 'Pincode opvragen',
  'pincode.title':
    'Welkom op het Aanmelden Parkeren portaal van de Gemeente Amsterdam',
  'pincode.description':
    'Hier kunt u uw parkeersessie voor uw {link} beheren.',
  'pincode.description.2': 'Wij verzenden uw pincode naar het geregistreerde telefoonnummer. Als controle vragen wij uw meldcode en de laatste 4 cijfers van uw telefoonnummer',
  'pincode.form.reportCode.label': 'Meldcode',
  'pincode.form.reportCode.placeholder': 'Voer uw meldcode in',
  'pincode.form.phoneNumber.label': 'Laatste 4 cijfers van uw telefoonnummer',
  'pincode.form.phoneNumber.placeholder': 'Voer de laatste 4 cijfers van uw telefoonnummer in',
  'pincode.button.label': 'Pincode opvragen',
  'pincode.errorMessage.title': 'Er is iets mis gegaan',
  'pincode.errorMessage.body': 'Probeer het later opnieuw',
  'pincode.login.label': 'Ik weet mijn meld- en pincode en wil inloggen',
  'pincode.successMessage.body': 'Als de ingevoerde gegevens kloppen ontvangt u SMS met uw pincode.',
  'form.error.required': 'Het verplichte veld {fieldName} is niet ingevuld',
  'form.error.licence.format':
    'Voer het kenteken in zonder spaties of streepjes.',
  'form.error.end-date.negative':
    'De eindtijd mag niet voor de begintijd zijn.',
  'form.input.text.clear.button': 'wissen',
  'parking.sessions': 'Parkeersessies',
  'parking.sessions.active.title': 'Actief',
  'parking.sessions.ended.title': 'Recent geëindigd',
  'parking.sessions.planned.title': 'Gepland',
  'parking.sessions.active.show-more': 'Bekijk alle actieve parkeersessies',
  'parking.sessions.planned.show-more': 'Bekijk alle geplande parkeersessies',
  'parking.session.list.empty.title':
    'Er zijn geen actieve of geplande parkeersessies',
  'parking.session.list.empty.text':
    'Start of plan een nieuwe parkeersessie',
  'parking.session.button.add': 'Nieuwe parkeersessie',
  'parking.session.welcome': 'Welkom',
  'parking.session.new.progress.parking': 'Parkeren',
  'parking.session.new.progress.visitor': 'Kenteken',
  'parking.session.new.progress.confirmation': 'Bevestiging',
  'parking.session.new.progress.balance': 'Saldo opwaarderen',
  'parking.session.new.progress.balance.short': 'Opwaarderen',
  'parking.session.form.licence-plate.label': 'Bezoeker kenteken',
  'parking.session.form.licence-plate.description':
    'Voer het kenteken in zonder streepjes, alleen letters en cijfers. Dit veld is verplicht',
  'parking.session.form.visitor.name.label': 'Naam bezoeker',
  'parking.session.form.visitor.name.placeholder': 'Voer een naam in',
  'parking.session.form.confirm': 'Bevestig parkeersessie',
  'parking.session.new': 'Nieuwe parkeersessie',
  'parking.session.step1.title.regularPermit': 'Wanneer komt uw bezoek?',
  'parking.session.step1.title.codePermit': 'Plan uw parkeersessie',
  'parking.session.step1.introduction.regularPermit':
    'U kiest de parkeertijd of stelt een eindtijd in. Als u de parkeersessie eerder wilt stoppen dan krijgt u het resterende tegoed terug.',
  'parking.session.step1.introduction.codePermit':
    'U kiest zelf de start- en eindtijd van de parkeersessie. Dit kan tot maximaal 28 dagen vanaf nu. U kunt een parkeersessie altijd eerder stoppen.',
  'parking.session.select-permit.title':
    'Om welke vergunning gaat het?',
  'parking.session.step1.zone.title':
    'Kies de start en eindtijd voor betaald parkeren',
  'parking.session.step1.zone.introduction':
    'Uw parkeerzone {parkingZone} heeft meerdere tijden voor betaald parkeren.  Aan het eind en aan het begin van de dag. Weet u deze nog niet? Deze kunt u tijdens de parkeersessie nog aanpassen.',
  'parking.session.step2.title': 'Om welk kenteken gaat het?',
  'parking.session.step2.introduction.regularPermit':
    'Voer het kenteken voor uw parkeersessie in of kies uit uw favoriete kentekens.',
  'parking.session.step2.introduction.codePermit':
  'Voer het kenteken voor uw parkeersessie in.',
  'parking.session.step2.introduction.assigned.plates.only':
    'Kies uit uw favorieten.',
  'parking.session.step3.title': 'Saldo opwaarderen',
  'parking.session.step3.introduction': 'Kies het bedrag waarmee u uw saldo wilt opwaarderen.',
  'license.plate': 'kenteken',
  'parking.session.form.back': 'terug',
  'parking.session.form.save.license.label': 'Aan favorieten toevoegen',
  'parking.session.confirmation': 'Parkeersessie bevestigd',
  'parking.session.start': 'Uw parkeersessie is gestart.',
  'parking.session.planned': 'Uw parkeersessie is gepland.',
  'parking.session.stopped': 'Uw parkeersessie is gestopt.',
  'parking.session.adjust.end.time': 'Eindtijd zelf aanpassen',
  'parking.session.adjust.find.zone.link.label': 'Waar vind ik de starttijd en de eindtijd van de parkeerplek?',
  'parking.session.adjust.find.zone.link.url': 'https://www.amsterdam.nl/parkeren-verkeer/parkeertarieven/',
  'parking.session.adjust.new.end.time': 'Nieuwe eindtijd: {newEndTime}',
  'parking.session.adjust.extention.time': 'Extra tijd betaald parkeren',
  'parking.session.adjust.extention.refund.time': 'Tijd tegoed',
  'parking.session.adjust.extention.refund.money': 'Geld tegoed',
  'parking.session.no.balance.active.since.label': 'Actief sinds {startDate}',
  'parking.session.unpaid.label': 'Let op: uw betaling wordt verwerkt. Wacht een paar minuten en vernieuw de pagina om het resultaat te zien. Na verwerking verdwijnt dit bericht.',
  'parking.session.adjust.extention.button.submit': 'Nieuwe eindtijd bevestigen',
  'parking.session.adjust.extention.payment.button.submit': 'Opwaarderen en sessie verlengen',
  'parking.session.adjust.extention.button.undo': 'Ongedaan maken',
  'parking.session.adjust.extention.single.zone.notification.title': 'Betaald parkeren: {startTime} - {endTime}',
  'parking.session.adjust.extention.single.zone.notification.label': 'In de parkeerzone die u gekozen heeft is tussen {endTime} en {startTime} parkeren gratis.',
  'parking.session.adjust.extention.selected.zone.notification.title': 'Gekozen tijd voor betaald parkeren: {startTime} - {endTime}',
  'parking.session.adjust.extention.selected.zone.notification.label': 'In de parkeerzone die u gekozen heeft is tussen {endTime} en {startTime} parkeren gratis.',
  'parking.session.adjust.extention.different.zone.notification.label': 'Heeft uw bezoeker ergens anders geparkeerd met een andere begin- en eindtijd voor betaald parkeren?',
  'parking.session.adjust.extention.different.zone.submit': 'Tijd betaald parkeren opgeslagen',
  'parking.session.adjust.extention.change.zone.label': 'Tijd betaald parkeren wijzigen',
  'parking.session.adjust.extention.low.balance.label': 'Saldotekort',
  'parking.session.adjust.low.time.balance.label': 'U heeft nog {timeBalance} tijdsaldo over, u kunt niet langer dan deze tijd parkeren.',
  'parking.session.adjust.extention.form.error':
    'Helaas is er iets misgegaan bij het bijwerken van het parkeersessie.',
  'parking.session.adjust.extention.earlier.zone.title': 'Uw betaalde parkeertijd is korter dan eerder vermeld',
  'parking.session.form.error.title': 'Oeps!',
  'parking.session.form.error.label':
    'Er is helaas iets mis gegaan bij het aanmaken van uw parkeersessie',
  'parking.session.form.error.low.time.balance.label': 'U heeft nog {timeBalance} tijdsaldo over, u kunt niet langer dan deze tijd parkeren.',
  'parking.session.form.error.not.within.regime.title': 'U kan in deze periode gratis parkeren',
  'parking.session.form.error.not.within.regime.full.day.label': 'In de parkeerzone die gekozen heeft is op deze dag tussen {startTime} en {endTime} parkeren gratis.',
  'parking.session.form.error.not.within.regime.label': 'In de parkeerzone die u gekozen heeft is tussen {endTime} en {startTime} parkeren gratis.',
  'parking.session.form.error.maximun.sessions.reached.title': 'Maximumaantal parkeersessies bereikt',
  'parking.session.form.error.maximun.sessions.reached.label': 'U kunt maximaal {maxAmount} parkeersessie(s) tegelijkertijd laten lopen. Dit aantal is al ingepland tijdens de gekozen tijdsduur. Plan een parkeersessie buiten deze tijd of pas de al geplande parkeersessie(s) aan.',
  'parking.session.form.back.to.overview.button.label': 'Terug naar overzicht',
  'parking.session.form.error.low.money.balance.title': 'Geldsaldo opwaarderen',
  'parking.session.form.error.low.money.balance.label': 'U heeft meer geldsaldo nodig voor deze parkeersessie. U kunt uw geldsaldo opwaarderen aan het eind van dit proces.',
  'parking.session.form.license.plates.error.label':
    'Helaas is er iets misgegaan bij het ophalen van uw kentekens',
  'parking.session.stop.error.label':
    'Helaas ging er iets mis bij het stoppen van uw parkeersessie',
  'parking-sessions.page.active.title': 'Actieve parkeersessies',
  'parking-sessions.page.planned.title': 'Geplande parkeersessies',
  'parking-sessions.page.no.parking-sessions.found.title': 'Geen parkeersessies gevonden',
  'parking-sessions.page.no.parking-sessions.found.active': 'actieve',
  'parking-sessions.page.no.parking-sessions.found.planned': 'geplande',
  'parking-sessions.page.no.parking-sessions.found.description': 'Voor dit kenteken heeft u geen geplande parkeersessie(s)',
  'parking-sessions.page.search.input.label': 'Zoek op kenteken:',
  'parking-sessions.page.search.input.placeholder': 'Kenteken',
  'parking-sessions.page.search.button.label': 'Zoeken',
  'days.today': 'Vandaag',
  'parking.balance.error.title': 'Oeps!',
  'parking.balance.error.label':
    'Er is helaas iets mis gegaan bij het berekenen van het bonnetje.',
  'parking.balance.starttime': 'Starttijd',
  'parking.balance.endtime': 'Eindtijd',
  'parking.balance.paid.parking': 'Betaald parkeren',
  'parking.balance.money.balance': 'Geldsaldo',
  'parking.balance.money.balance.remaining': 'Resterend geldsaldo',
  'parking.balance.time.balance': 'Tijdsaldo',
  'parking.balance.time.balance.remaining': 'Resterend tijdsaldo',
  'parking.balance.paid.parking.time': 'Tijd betaald parkeren',
  'parking.balance.parking.cost': 'Parkeerkosten',
  'parking.balance.parking.cost.description':
    'Parkeerkosten per uur {parkingCostsPerHour}; Goed voor {remainingParkingTime} parkeren',
  'parking.session.from-til': 'vanaf {startDate} tot {endDate}',
  'parking.session.until': 'tot',
  'parking.session.stop': 'Sessie stoppen',
  'parking.session.stop.notification': 'Uw parkeersessie is gestopt.',
  'parking.session.addtional.details.created.time': 'Sessie gepland: {createdTime}',
  'parking.session.addtional.details.duration': 'Betaald parkeren: {duration}',
  'parking.session.addtional.details.cost': 'Uw parkeerkosten: {cost}',
  'parking.session.additional.details.no.balance.active.till.next': 'Uw sessie blijft actief tot u de volgende sessie met een ander kenteken start.',
  'back.to.overview': 'Terug naar uw overzicht',
  'parking.session.show.details': 'meer details tonen',
  'parking.session.less.details': 'minder details tonen',
  'parking.session.planned.edit': 'Wijzigen',
  'parking.session.planned.stop': 'Verwijderen',
  'parking.session.planned.stop.error': 'Helaas ging er iets mis tijdens het annuleren van de parkeersessie',
  'parking.session.progress.mobile': 'Stap {currentProgress} van {steps}',
  'parking.session.progress.step1': 'Parkeren',
  'parking.session.progress.step2': 'Kenteken',
  'parking.session.progress.step3': 'Saldo opwaarderen',
  'parking.session.progress.step4': 'Bevestiging',
  'parking.session.when.visiting': 'Wanneer start de parkeersessie?',
  'parking.session.when.visiting.details':
    'Kies een starttijd en een eindtijd. U kunt de parkeersessie altijd eerder beëindigen. U krijgt het resterende tegoed terug.',
  'parking.session.when.visiting.subtext':
    'Vul alle informatie in, tenzij er optioneel staat. Die informatie is niet verplicht om in te vullen.',
  'parking.session.form.start-day.label': 'Startdatum',
  'parking.session.form.start-time.label': 'Starttijd',
  'parking.session.form.end-day.label': 'Einddatum',
  'parking.session.form.end-time.label': 'Eindtijd',
  'parking.session.form.parking-time.label': 'Parkeertijd',
  'parking.session.time-remaining': 'nog {value}',
  'parking.session.form.licenseplates.manual.input':
    'Zelf een kenteken invoeren',
  'parking.session.form.licenseplates.favourite.label':
    'Of kies een kenteken uit uw favorieten',
  'parking.session.form.licenseplates.favourite.assigned.plates.only.label':
    'Kies een kenteken uit uw favorieten',
  'parking.session.form.licenseplates.favourite.limit.exceeded': 'U heeft al {limit} kentekens opgeslagen in uw favorieten. Ga naar favorieten om dit aan te passen.',
  'parking.session.form.licenseplates.invalid.error':
    'Voer een kenteken in of selecteer een favoriet om door te gaan.',
  'parking.session.form.codevergunning.time.choose.custom': 'Zelf eindtijd en datum kiezen',
  'overview.balance.title': ' Uw saldo',
  'overview.balance.time': ' Tijd',
  'overview.balance.money': ' Geld',
  'overview.balance.money.remaining.time':
    'Goed voor {remainingParkingTime} parkeren',
  'overview.balance.no.cost.title': 'Geen saldo van toepassing',
  'overview.balance.no.cost.description':
    'Uw {permitType} werkt zonder saldo. De vergunning is onbeperkt geldig.',
  'favorites.title': 'Kentekenfavorieten',
  'favorites.description':
    'Hier staan uw favoriete kentekens zodat u altijd snel een parkeersessie kunt starten.',
  'favorites.button.add': 'Kenteken als favoriet toevoegen',
  'favorites.maximum.limit.reached.title': 'Maximaal aantal bereikt',
  'favorites.maximum.limit.reached.description': 'U kunt maximaal {maximumAmount} kentekens opslaan. Wilt u een nieuwe toevoegen, verwijder dan eerst een kenteken.',
  'favorites.limit.reached.title': 'Maximaal aantal kentekenfavorieten bereikt',
  'favorites.limit.reached.description':
    'Wilt u een kenteken vervangen? Neem dan contact met ons op.',
  'favorites.link.email': ' ',
  'favorites.delete.confirmation': 'Kenteken is verwijderd',
  'favorites.add.confirmation': 'Kenteken toegevoegd aan favorieten',
  'favorites.cannot.add.title': 'Kenteken toevoegen',
  'favorites.cannot.add.description':
    'Wilt u een ander kenteken toevoegen of vervangen? Neem dan contact met ons op.',
  'favorites.button.submit': 'Toevoegen',
  'favorites.button.cancel': 'Annuleren',
  'favorites.button.delete': 'Verwijderen',
  'favorites.form.error.title': 'Oeps!',
  'favorites.fetch.error.label':
    'Helaas ging er iets mis bij het ophalen van uw kentekens',
  'favorites.form.error.label':
    'Er is helaas iets mis gegaan bij het toevoegen van uw kenteken',
  'favorites.form.delete.error.label':
    'Er is helaas iets mis gegaan bij het verwijdering van uw kenteken',
  'favorites.form.title': 'Kenteken als favoriet toevoegen',
  'transaction.page.title': 'Parkeerhistorie & transacties',
  'transaction.page.got-a-question': 'Vraag over een parkeersessie? {link}',
  'transactions.page.no.transactions.found.heading': 'Er is nog geen historie beschikbaar',
  'transaction.page.contact-us': 'Contact opnemen',
  'transaction.session.from': 'Van',
  'transaction.session.to': 'Tot',
  'transaction.session.permit.caretaker.label':
    'Parkeervergunning voor gehandicapte bezoekers',
  'transaction.session.permit.label': 'Bezoekersvergunning',
  'transaction.session.visitor.label': 'Bezoeker {value}',
  'transaction.session.more-details': 'Meer details tonen',
  'transaction.session.less-details': 'Toon minder details',
  'transaction.session.from.visitor': 'Aangemaakt via bezoekersaccount',
  'transaction.session.cost': 'Parkeerkosten',
  'transaction.session.creationTime': 'Sessie gepland: {value}',
  'transaction.session.updateTime': 'Laatste wijziging: {value}',
  'transaction.session.regimeTime': 'Betaald parkeren: {value}',
  'transaction.session.duration': 'Tijd betaald parkeren',
  'transaction.session.cost.extended': 'Parkeerkosten',
  'transaction.session.cancelled.label': 'Geannuleerd',
  'transaction.topup.type': 'Geldsaldo {paymentOption} opgewaardeerd',
  'transaction.refund.type': 'Geldsaldo teruggevorderd',
  'transaction.topup.amount.label': 'Opwaardeerbedrag',
  'transaction.refund.amount.label': 'Terugvorderbedrag',
  'transaction.reclaim.fees.label': 'Parkeergeld terugvragen',
  'transaction.reclaim.fees.description': 'Bent u het niet eens met de verrekende parkeerkosten en denkt u dat er iets fout is gegaan?',
  'transaction.reclaim.fees.link.label': 'U kunt hier meer lezen hoe u uw parkeergeld kunt terugvragen',
  'transaction.reclaim.fees.link.href': 'https://www.amsterdam.nl/parkeren-verkeer/parkeergeld-terugvragen/',
  'visitor.account.settings.description':
    'Met het bezoekersaccount kunnen uw bezoekers zelf hun parkeersessie plannen en betalen. U stelt een deel van uw tijdsaldo beschikbaar binnen uw bezoekersaccount.',
  'visitor.account.settings.description2':
    'Uw bezoek kan inloggen op het Aanmelden parkeren platform met onderstaande inloggegevens.',
  'visitor.account.settings.balance.title': 'Saldo',
  'visitor.account.settings.login.details.title': 'Inloggegevens',
  'visitor.account.settings.login.details.copy': 'Inloggegevens kopiëren',
  'visitor.account.settings.login.details.edit': 'Wijzigen',
  'visitor.account.settings.visitor.login.description':
    'Uw bezoek kan inloggen op ',
  'visitor.account.settings.visitor.login.link':
    'https://aanmeldenparkeren.amsterdam.nl',
  'visitor.account.settings.visitor.login.link.label':
    'aanmeldenparkeren.amsterdam.nl',
  'visitor.account.settings.form.title': 'Pincode wijzigen',
  'visitor.account.settings.form.button.submit': 'Wijzigen',
  'visitor.account.settings.form.button.cancel': 'Annuleren',
  'visitor.account.settings.form.pinCode.label': 'Nieuwe pincode',
  'visitor.account.settings.form.old.pinCode.label': 'Huidige pincode',
  'visitor.account.settings.form.error':
    'Helaas is er iets misgegaan tijdens het updaten van de pincode.',
  'visitor.account.settings.form.invalid.pin.error':
    'Huidige pincode is ongeldig.',
  'visitor.account.settings.form.pinCode.description': '4 cijfers',
  'visitor.account.settings.form.pinCode.length.error': 'Het veld Nieuwe pincode moet 4 cijfers zijn',
  'visitor.account.settings.form.pinCode.placeholder':
    'Voer uw nieuwe pincode in',
  'visitor.account.settings.form.old.pinCode.placeholder':
    'Voer uw huidige pincode in',
  'visitor.account.settings.form.pinCodeRepeat.label': 'Herhaal nieuwe pincode',
  'visitor.account.settings.form.pinCodeRepeat.placeholder':
    'Herhaal uw nieuwe pincode',
  'visitor.account.settings.form.success': 'Uw pincode is gewijzigd.',
  'visitor.account.settings.copy.success': 'Inloggegevens zijn gekopieërd',
  'visitor.account.settings.reportCode': 'Meldcode',
  'visitor.account.settings.pinCode': 'Pincode',
  'visitor.account.settings.allocate.title': 'Tijd toekennen',
  'visitor.account.settings.reclaim.title': 'Tijd terugvorderen',
  'visitor.account.settings.allocate.description': 'Uw kunt tijd toekennen aan uw bezoekersaccount. De toegekende tijd wordt van het tijdsaldo van uw bezoekersvergunning afgetrokken.',
  'visitor.account.settings.reclaim.description': 'Uw kunt tijd terugvorderen van uw bezoekersaccount. De teruggevorderde tijd wordt aan het tijdsaldo van uw bezoekersvergunning toegevoegd.',
  'visitor.account.settings.allocate.time.available': 'Beschikbare tijd binnen uw account: {availableTime}',
  'visitor.account.settings.allocate.time.7200000': '2 uur',
  'visitor.account.settings.allocate.time.10800000': '3 uur',
  'visitor.account.settings.allocate.time.14400000': '4 uur',
  'visitor.account.settings.allocate.time.28800000': '8 uur',
  'visitor.account.settings.allocate.time.43200000': '12 uur',
  'visitor.account.settings.allocate.confirmation': '{timeAdded} toegevoegd aan het tijdsaldo van uw bezoekersaccount. De uren zijn van het tijdsaldo van uw bezoekersvergunning afgetrokken.',
  'visitor.account.settings.reclaim.confirmation': 'Tijdsaldo van {timeReclaimed} teruggevorderd van uw bezoekersaccount. De uren worden toegevoegd aan het tijdsaldo van uw bezoekersvergunning. ',
  'visitor.account.settings.allocate.error.description': 'Helaas is er iets misgegaan tijdens het overschrijven van het tijdsaldo',
  'visitor.account.settings.allocate.button.submit': 'Toekennen',
  'visitor.account.settings.reclaim.button.submit': 'Terugvorderen',
  'visitor.account.settings.allocate.button.cancel': 'Annuleren',
  'account.settings.personal.data': 'Uw gegevens',
  'account.settings.personal.data.name': 'Naam',
  'account.settings.personal.data.address': 'Adres',
  'account.settings.personal.data.address.read.more':
    'Meer lezen over een verhuizing doorgeven',
  'account.settings.personal.data.address.description':
    'Wilt u een verhuizing doorgeven? Neem dan contact met ons op of lees meer op ',
  'account.settings.personal.data.address.link.label':
    'amsterdam.nl/parkeren.',
  'account.settings.personal.data.address.link.href':
    'https://www.amsterdam.nl/parkeren/',
  'account.settings.personal.data.modify.button': 'Wijzigen',
  'account.settings.personal.data.phonenumber': 'Telefonisch aanmelden',
  'account.settings.personal.data.phonenumber.call.to.register.session':
    'Door te bellen naar {phoneNumber} (lokaal tarief) kunt u via het keuzemenu parkeersessies starten.',
  'account.settings.personal.data.phonenumber.number.to.call.for.pin':
    '020 215 0077',
  'account.settings.personal.data.phonenumber.description':
  'Registreer uw telefoonnummer en stel een pincode in voor snelle identificatie. Wij koppelen uw meldcode aan het telefoonnummer. U hoeft vervolgens uw meldcode niet meer door te geven. U registreert uw telefoonnummer in Mijn Parkeren.',
  'account.settings.personal.data.phonenumber.registered.phonenumber.description':
  'Wilt u uw telefoonnummer wijzigen? Dit doet u in Mijn Parkeren. Ga hiervoor naar {link}.',
  'account.settings.personal.data.phonenumber.registered.phonenumber.label':
    'Geregistreerd telefoonnummer',
  'account.settings.personal.data.phonenumber.register.phonenumber.link.label': 'parkeervergunningen.amsterdam.nl',
  'account.settings.personal.data.phonenumber.register.phonenumber.link.href': 'https://parkeervergunningen.amsterdam.nl/',
  'account.settings.personal.data.phonenumber.register.phonenumber.button.label':
    'Registreer telefoonnummer',
  'account.settings.personal.data.phonenumber.more.help.link.label':
    'Meer informatie over telefonisch bezoek aanmelden',
  'account.settings.personal.data.phonenumber.more.help.link.href':
    'https://www.amsterdam.nl/parkeren-verkeer/parkeervergunning/parkeertijd-bezoek-doorgeven/',
  'account.settings.credentials': 'Inloggegevens',
  'account.settings.credentials.reportCode': 'Meldcode',
  'account.settings.credentials.pinCode': 'Pincode',
  'account.settings.credentials.pinCode.form.title': 'Pincode wijzigen',
  'account.settings.credentials.pinCode.form.pin.label': 'Nieuwe pincode',
  'account.settings.credentials.pinCode.form.pin.repeat.label': 'Herhaal nieuwe pincode',
  'account.settings.credentials.pinCode.form.pin.placeholder': 'Voer uw nieuwe pincode in',
  'account.settings.credentials.pinCode.form.success': 'Uw pincode is gewijzigd.',
  'account.settings.credentials.pinCode.form.error': 'Helaas is er iets misgegaan bij het bijwerken van het pincode.',
  'account.settings.credentials.pinCode.form.pinCode.matches.error': 'Pincodes komen niet overeen',
  'account.settings.credentials.pinCode.form.button.submit': 'Wijzigen',
  'account.settings.credentials.pinCode.form.button.cancel': 'Annuleren',
  'top.up.balance.form.amount.title': 'Kies een bedrag',
  'top.up.balance.form.amount.description': 'Kies het bedrag waarmee u uw saldo wilt opwaarderen.',
  'top.up.balance.form.amount.error': 'Selecteer een bedrag om verder te gaan',
  'top.up.balance.form.payment.details.title': 'Betaalgegevens',
  'top.up.balance.form.payment.details.description': 'Kies uw bank om met iDeal te betalen.',
  'top.up.balance.form.step1.title': 'Saldo opwaarderen',
  'top.up.balance.form.step1.title.success': 'Geldsaldo opgewaardeerd',
  'top.up.balance.form.step2.title': 'Bevestiging',
  'top.up.balance.form.submit': 'Nu betalen',
  'top.up.balance.money.balance': 'Geldsaldo',
  'top.up.balance.money.new.balance': 'Nieuwe geldsaldo',
  'top.up.balance.top.up.amount': 'Opwaardeerbedrag',
  'top.up.balance.error.title': 'Betaling mislukt',
  'top.up.balance.error.message': 'De iDeal betaling is mislukt, er is geen geld van uw rekening afgetrokken. Probeer de betaling opnieuw.',
  'top.up.balance.success.message': 'Uw geldsaldo is opgewaardeerd met {amount}.',
  'top.up.balance.pending.message': 'Wij verwerken uw bestelling. Het kan een paar minuten duren om te reflecteren.',
  'visitor.account.discount': '{percentage} procent korting. Regulier tarief {value}',
  'visitor.account.permit.information.title': 'Vergunninggebied',
  'visitor.account.name': 'Bezoeker',
  'visitor.account.welcome': 'Hallo bezoeker!',
  'visitor.account.balance.title': 'Saldo',
  'visitor.account.balance.time': 'Tijdsaldo',
  'visitor.account.parking.title': 'Parkeren',
  'visitor.account.license.plate.form.vehicleId.label': 'Voer uw kenteken in',
  'visitor.account.license.plate.form.vehicleId.description': 'Voer het kenteken in zonder streepjes, alleen cijfers en letters.',
  'visitor.account.license.plate.form.vehicleId.placeholder': 'Kenteken',
  'visitor.account.license.plate.form.submit.button': 'Kenteken bevestigen',
  'visitor.account.license.plate.form.submit.error': 'Het door u ingevulde kenteken komt niet overeen met wat wij in onze administratie hebben.',
  'visitor.account.parking.session.new': 'Parkeersessie voor {vehicleId}',
  'visitor.account.parking.session.step1.title': 'Hoe lang wilt u parkeren?',
  'visitor.account.parking.session.step1.introduction1': 'Kies een starttijd en een eindtijd. Als u toch langer wilt parkeren, kunt u altijd uw parkeersessie verlengen.',
  'visitor.account.parking.session.step1.introduction2': 'Vul alle informatie in, tenzij er "optioneel" staat. Die informatie is niet verplicht om in te vullen.',
  'visitor.account.parking.session.step1.remaining.time.warning': 'U heeft nog {remainingTime} tijdsaldo over, u kunt niet langer dan deze tijd parkeren.',
  'visitor.account.parking.session.progress.step1': 'Parkeren',
  'visitor.account.parking.session.progress.step2': 'Betalen',
  'visitor.account.parking.session.progress.step3': 'Bevestiging',
  'visitor.account.parking.balance.parking.cost.description': 'Parkeerkosten per uur {parkingCostsPerHour}',
  'visitor.account.parking.time.start.now': 'Sessie nu starten',
  'visitor.account.parking.time.end-time.label': 'Eindtijd',
  'visitor.account.parking.time.parking-time.label': 'Parkeertijd',
  'visitor.account.parking.time.choose.custom': 'Zelf eindtijd en datum kiezen',
  'visitor.account.parking.time.1800000': '30 min',
  'visitor.account.parking.time.3600000': '1 uur',
  'visitor.account.parking.time.7200000': '2 uur',
  'visitor.account.parking.time.10800000': '3 uur',
  'visitor.account.parking.sessions.error.label':
    'Helaas is er iets misgegaan bij het ophalen van uw parkeersessies',
  'login.page.payment.error.message': 'Vanwege een storing is het niet mogelijk om geld op te waarderen. Onze excuses voor het ongemak.',
}
