import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import {
  useContext
} from 'react'
import { StoreContext } from '@utils/zustand/store-provider'
import {
  IUserStore
} from '@store/user'
import { fullState } from '@store/user/selectors'
import { initUserStore } from '@hooks/use-create-user-store'

const useUserStore = () => {
  const storeFromContext = useContext(StoreContext)?.user
  const store = storeFromContext || initUserStore()
  const autoGeneratedSelectorHooks = createSelectorHooks<IUserStore>(store)

  return {
    ...autoGeneratedSelectorHooks,
    fullState: store(fullState),
  }
}

export default useUserStore
