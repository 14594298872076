import styled from 'styled-components'
import Heading from '@components/typography/heading'
import { Column } from '@components/layout'

export const StyledColumn = styled(Column)`
  margin-bottom: 8rem;
`

export const StyledHeading = styled(Heading)`
  margin: 4rem 0 6rem 0;
`
