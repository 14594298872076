import styled, { css } from 'styled-components'
import TextLink from '@components/text-link'
import { Container } from '@components/layout'
import Paragraph from '@components/typography/paragraph'
import Icon from '@components/icon'

export const StyledContainer = styled(Container)(
  () => css`
    height: 4.4rem;
    position: relative;
    z-index: ${({ theme }) => theme.Z_INDEX.PAGE_HEADER};

    background: ${({ theme }) => theme.COLOR.NEUTRAL_GREY_1};

    @media screen and (min-width: ${({ theme }) =>
        theme.LAYOUT.BREAKPOINT.MD}px) {
      background: ${({ theme }) => theme.COLOR.NEUTRAL_GREY_1};
    }
  `
)

export const StyledHamburgerButton = styled.button`
  height: 4.4rem;
  border: none;
  background: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    display: none;
  }
`

interface StyledMenuProps {
  $isOpen: boolean
}

export const StyledMenu = styled.div<StyledMenuProps>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  position: absolute;
  top: 4.4rem;
  left: 0;
  background: ${({ theme }) => theme.COLOR.NEUTRAL_GREY_1};
  border-top: 1px solid ${({ theme }) => theme.COLOR.NEUTRAL_GREY_2};
  max-width: 37.5rem;
  width: calc(100% - 4.6rem);
  z-index: ${({ theme }) => theme.Z_INDEX.NAVIGATION};

  a { 
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    position: static;
    max-width: none;
    border-top: none;
    display: flex;

    a { 
      width: fit-content;
    }
  }
`

interface StyledOverlayProps {
  $isActive?: boolean
}

export const StyledOverlay = styled.div<StyledOverlayProps>(
  ({ theme: { COLOR, Z_INDEX }, $isActive }) => css`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: ${COLOR.PRIMARY_BLACK};
    opacity: 0;
    z-index: ${Z_INDEX.OVERLAY};
    bottom: 0;
    pointer-events: none;

    ${$isActive &&
    css`
      opacity: 0.5;
      pointer-events: all;
    `}

    @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.SM}px) {
      top: 12.5rem;
    }
  `
)

export const StyledNav = styled.nav`
  width: 100%;

  &:not(:last-child) {
    border-bottom: 4px solid ${({ theme }) => theme.COLOR.NEUTRAL_GREY_2};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    display: inline-block;
    width: auto;

    &:first-child,
    &:last-child {
      display: none;
    }

    &:not(:last-child) {
      border-bottom: none;
    }
  }
`

export const StyledMainNav = styled.nav`
  display: flex;
  justify-content: start;
  gap: 1.6rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    &:not(:last-child) {
      border-bottom: 4px solid ${({ theme }) => theme.COLOR.NEUTRAL_GREY_2};
    }

    display: inline-block;
    width: auto;

    &:not(:last-child) {
      border-bottom: none;
    }
  }
`

export const StyledTextLink = styled(TextLink)`
  padding: 1.2rem 1.2rem 1.2rem 1.6rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.SM}px) {
    padding: 1.2rem 1.2rem 1.2rem 3.2rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    padding: 0.9rem 1.2rem 1.2rem 0;
  } ;
`

export const StyledSecondaryTextLink = styled(StyledTextLink)`
  font-weight: 400;
`

export const StyledLogoutButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${StyledTextLink} {
    width: auto;
  }
`

export const StyledMainNavWrapper = styled.div`
  display: flex;
  gap: 1.6rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    display: block;
  } ;
`

export const StyledParagraph = styled(Paragraph)`
  padding: 1.5rem 1.6rem;
  color: ${({ theme }) => theme.COLOR.NEUTRAL_GREY_5};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.SM}px) {
    padding: 1.5rem 3.2rem;
  } ;
`

export const StyledIcon = styled(Icon)`
  height: 1.6rem;
`
