import create from 'zustand/vanilla'
import { devtools } from 'zustand/middleware'
import { WritableDraft } from 'immer/dist/internal'
import immer from '@utils/zustand/immer-middleware'
import merge from 'lodash/merge'
import { StoreApi } from 'zustand'

export const STORE_NAME = 'user'

type StoreSet = (fn: (draft: WritableDraft<IUserStore>) => void) => void;

export enum AccountScope {
  PERMIT_HOLDER = 'permitHolder',
  VISITOR = 'visitor'
}
export interface IUser {
  access_token: string,
  reportcode: number,
  family_name: string,
  initials: string,
  walletBalance: {
    // etc...
  } | null
  scope: AccountScope
}

export interface IUserState {
  user: IUser | Record<any, any>
}

export interface IUserStore extends IUserState {
  setUser: (user: IUser) => void,
  hydrate: (input: IUserState) => void,
  clear: () => void,
}
let store: StoreApi<IUserStore> | undefined

const initialState:IUserState = {
  user: {}
}

const createState = (preLoadedState?: IUserState) => (set: StoreSet, get: () => IUserState) => ({
  ...initialState,
  ...preLoadedState,
  setUser: (user: IUser) => set((state) => {
    state.user = user
  }),
  hydrate: (input: IUserState) => set((state: IUserState) => {
    if (state && input?.user) {
      return merge({}, get(), input)
    }
  }),
  clear: () => set(() => initialState),
})

const getStore = (preLoadedState?:IUserState) => {
  if (process.browser && store) {
    if (preLoadedState) {
      store.getState().hydrate(preLoadedState)
    }
    return store
  }

  store = create<IUserStore>(
    devtools(
      immer(createState(preLoadedState)), { name: STORE_NAME }
    )
  )

  return store
}

export default getStore
