import create from 'zustand'
import getStore, {
  IParkingSessionsState,
  IParkingSessionsStore
} from '@store/parking-sessions'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

export const initParkingSessionsStore = (preloadedState?:IParkingSessionsState) => {
  return create<IParkingSessionsStore>(getStore(preloadedState))
}

export const useCreateParkingSessionsStore = (initialState?:IParkingSessionsState) => {
  const state = typeof initialState === 'string' ? JSON.parse(initialState) : initialState
  const _store = initParkingSessionsStore(state)

  useIsomorphicLayoutEffect(() => {
    _store.getState().hydrate(state)
  }, [ _store, state ])

  return _store
}

export default useCreateParkingSessionsStore
