import {
  ReactNode, useEffect, useId, useState
} from 'react'
import IconNames from '@components/icon/icon-names'
import {
  StyledAccordion,
  StyledAccordionButton,
  StyledAccordionBody,
  StyledAccordionContainer,
  StyledAccordionCaret,
  StyledAccordionIcon,
  StyledAccordionTitle,
  StyledBadge
} from './style'
import { HeadingTypes } from '@theme/basic/typography'

export enum initialAccordionItemStates {
  CLOSED,
  OPEN,
}

export type AccordionVariant = 'text' | 'solid'

interface Props {
  title?: ReactNode
  className?: string
  icon?: IconNames
  isActive?: boolean
  variant?: AccordionVariant
  children?: ReactNode
  initialState?:initialAccordionItemStates,
  badge?: string | number,
  headingLevel?: HeadingTypes
}

function AccordionItem ({
  title,
  icon,
  isActive = false,
  children,
  variant = 'text',
  className,
  initialState = initialAccordionItemStates.CLOSED,
  badge,
  headingLevel
}: Props) {
  const [ isOpen, setOpen ] = useState(!isActive ? true : !!initialState)
  const panelId = useId()
  const buttonId = useId()

  useEffect(() => {
    setOpen(!isActive ? true : !!initialState)
  }, [ isActive, initialState ])

  const onToggle = () => {
    if (!isActive) return
    setOpen(!isOpen)
  }

  const animation = {
    initial: isOpen ? 'open' : 'collapsed',
    animate: isOpen ? 'open' : 'collapsed',
    variants: {
      open: { opacity: 1, height: 'auto', display: 'block' },
      collapsed: { opacity: 0, height: 0, display: 'none' },
    },
    transition: {
      ease: [ 0.45, 0, 0.55, 1 ],
      duration: 0.4,
    },
  }

  return (
    <StyledAccordion className={className}>
      <StyledAccordionButton $variant={variant} type="button" disabled={!isActive} onClick={onToggle} $isActive={isActive} aria-expanded={isOpen} id={buttonId} aria-controls={panelId}>
        {/* @todo This could be changed to always render, but hide it with CSS media queries (instead of useMedia) */}
        {isActive && (
          <StyledAccordionCaret $variant={variant} name={!isOpen ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_UP} />
        )}
        {icon && <StyledAccordionIcon name={icon} />}
        <StyledAccordionTitle type={HeadingTypes.HEADING_3} level={headingLevel} $variant={variant}>
          {title}
          {!!badge && <StyledBadge>{badge}</StyledBadge>}
        </StyledAccordionTitle>
      </StyledAccordionButton>
      <StyledAccordionBody {...animation} id={panelId} role="region" aria-labelledby={buttonId} $isOpen={isOpen} $isActive={isActive}>
        <StyledAccordionContainer>{children}</StyledAccordionContainer>
      </StyledAccordionBody>
    </StyledAccordion>
  )
}

export default AccordionItem
