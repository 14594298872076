import create from 'zustand'
import getStore, {
  ILicensePlatesState,
  ILicensePlatesStore
} from '@store/license-plates'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

export const initLicensePlatesStore = (preloadedState?:ILicensePlatesState) => {
  return create<ILicensePlatesStore>(getStore(preloadedState))
}

export const useCreateLicensePlatesStore = (initialState?:ILicensePlatesState) => {
  const state = typeof initialState === 'string' ? JSON.parse(initialState) : initialState
  const _store = initLicensePlatesStore(state)

  useIsomorphicLayoutEffect(() => {
    _store.getState().hydrate(state)
  }, [ _store, state ])

  return _store
}

export default useCreateLicensePlatesStore
