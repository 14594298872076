import React, {
  createContext,
  ReactNode
} from 'react'
import { StoreApi, UseBoundStore } from 'zustand'
import { ILicensePlatesStore } from '@store/license-plates'
import { IParkingSessionsStore } from '@store/parking-sessions'
import { IPermitsStore } from '@store/permits'
import { ITransactionsStore } from '@store/transactions'
import { IUserStore } from '@store/user'
import { IToastStore } from '@store/toasts'

export type Stores= {
  licensePlates: UseBoundStore<ILicensePlatesStore, StoreApi<ILicensePlatesStore>>;
  'parking-sessions': UseBoundStore<IParkingSessionsStore, StoreApi<IParkingSessionsStore>>;
  permits: UseBoundStore<IPermitsStore, StoreApi<IPermitsStore>>;
  transactions: UseBoundStore<ITransactionsStore, StoreApi<ITransactionsStore>>;
  user: UseBoundStore<IUserStore, StoreApi<IUserStore>>;
  toasts: UseBoundStore<IToastStore, StoreApi<IToastStore>>;
} | null

interface IStoreProviderProps {
  children: ReactNode,
  stores: Stores
}

export const StoreContext = createContext<Stores>(null)

export const StoreProvider = ({ children, stores }: IStoreProviderProps) => {
  return (<StoreContext.Provider value={stores}>{children}</StoreContext.Provider>)
}
