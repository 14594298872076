import localFont from 'next/font/local'

const AmsterdamSans = localFont({
  src: [
    {
      style: 'normal',
      weight: '300',
      path: '../fonts/woff2/AmsterdamSans-Light.woff2',
    },
    {
      style: 'normal',
      weight: '400',
      path: '../fonts/woff2/AmsterdamSans-Regular.woff2',
    },
    {
      style: 'normal',
      weight: '700',
      path: '../fonts/woff2/AmsterdamSans-Bold.woff2',
    },
    // {
    //   style: 'normal',
    //   weight: '800',
    //   path: '../fonts/woff2/AmsterdamSans-ExtraBold.woff2',
    // },
    // {
    //   style: 'italic',
    //   weight: '400',
    //   path: '../fonts/woff2/AmsterdamSans-Italic.woff2',
    // },
    // {
    //   style: 'italic',
    //   weight: '700',
    //   path: '../fonts/woff2/AmsterdamSans-BoldItalic.woff2',
    // },
  ],
  display: 'swap',
  fallback: [ 'arial', 'sans-serif' ],
  preload: true,
})

const FONT = {
  PRIMARY: AmsterdamSans.style.fontFamily,
}

export default FONT
