import styled, { css } from 'styled-components'
import TextLink from '@components/text-link'
import Paragraph from '@components/typography/paragraph'

export const FooterTop = styled.div(({ theme: { COLOR } }) => css`
  font-size: 1.4rem;
  font-weight: 400;
  background-color: ${COLOR.NEUTRAL_GREY_4};
  color: ${COLOR.PRIMARY_WHITE};
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
`)

export const FooterTopParagraph = styled(Paragraph)`
    margin-bottom: 2rem;
`

export const FooterTopList = styled.ul`
  margin-inline-start: 2.4rem;
`

export const FooterTopLink = styled(TextLink)`
  line-height: 2.8rem;
`

export const FooterBottom = styled.div(({ theme: { COLOR } }) => css`
    background-color: ${COLOR.PRIMARY_WHITE};
`)

export const FooterBottomLink = styled(TextLink)`
    margin: 1.1rem 3.2rem 1.1rem 0;
`
