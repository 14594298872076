import { ReactNode } from 'react'
import { StyledAccordion } from './style'

interface Props {
  children?: ReactNode
}

function Accordion ({ children }: Props) {
  return <StyledAccordion>{children}</StyledAccordion>
}

export default Accordion
