import styled, { css } from 'styled-components'
import Paragraph from '@components/typography/paragraph'
import { HeadingTypes, ParagraphTypes } from '@theme/basic/typography'
import Icon, { IIconProps } from '@components/icon'
import Heading from '@components/typography/heading'

interface IMessageContainerProps {
  color: string
}

export const StyledContainer = styled.div<IMessageContainerProps>(
  ({ color }) => css`
    padding: 0.2rem;
    background-color: ${color};
    display: flex;
  `
)
export const StyledTextContainer = styled.div(
  ({ theme: { COLOR } }) => css`
    display: block;
    width: 100%;
    padding: 1.2rem;
    background: ${COLOR.PRIMARY_WHITE};
  `
)
export const StyledTitle = styled(Heading).attrs({
  type: HeadingTypes.HEADING_4
})(
  () => css`
    display: block;
  `
)
export const StyledDescription = styled(Paragraph).attrs({
  type: ParagraphTypes.PARAGRAPH_2
})(
  ({ theme: { COLOR } }) => css`
    color: ${COLOR.GREY};
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  `
)
export const StyledIcon = styled(Icon)<IIconProps>(
  ({ theme: { COLOR } }) => css`
    margin: 1.2rem;
    align-self: flex-start;
    color: ${COLOR.PRIMARY_WHITE};
    width: 2rem;
  `
)

