import { createGlobalStyle } from 'styled-components'

// Keep this file as small as possible.

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
  }
  
  body {
    font-family: "Amsterdam Sans", Arial, sans-serif;
  }

  //  Toastify styles copied and adabted as to not have to include entire required import stylesheet here 
  //  react-toastify/dist/ReactToastify.css

  .Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, 9999);
    position: fixed;
    padding: 4px;
    box-sizing: border-box;
  }

  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em;
  }
  
  @media only screen and (max-width : 480px) {
    .Toastify__toast-container {
      padding: 0;
      left: 0;
      margin: 0;
    }

    .Toastify__toast-container--bottom-left {
      bottom: 0;
      transform: translateX(0);
    }
  }

  .Toastify__toast {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    cursor: default;
    direction: ltr;
    /* webkit only issue #791 */
    z-index: 0;
  }
  .Toastify__toast--close-on-click {
    cursor: pointer;
  }
  .Toastify__toast-body {
    margin: auto 0;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    padding: 6px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .Toastify__toast-body > div:last-child {
    word-break: break-word;
    -ms-flex: 1;
        flex: 1;
  }
  .Toastify__toast-icon {
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    width: 20px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: -ms-flexbox;
    display: flex;
  }
  
  .Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.7s;
  }
  
  .Toastify--animate-icon {
    animation-fill-mode: both;
    animation-duration: 0.3s;
  }
  
  @media only screen and (max-width : 480px) {
    .Toastify__toast {
      margin-bottom: 0;
      border-radius: 0;
    }
  }


  .Toastify__close-button {
    color: #fff;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    -ms-flex-item-align: start;
        align-self: flex-start;
  }

  .Toastify__close-button > svg {
    fill: currentColor;
  }
  
  @keyframes Toastify__bounceInRight {
    from, 60%, 75%, 90%, to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
      opacity: 0;
      transform: translate3d(3000px, 0, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(-25px, 0, 0);
    }
    75% {
      transform: translate3d(10px, 0, 0);
    }
    90% {
      transform: translate3d(-5px, 0, 0);
    }
    to {
      transform: none;
    }
  }
  @keyframes Toastify__bounceOutRight {
    20% {
      opacity: 1;
      transform: translate3d(-20px, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(2000px, 0, 0);
    }
  }

  .Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight;
  }

  .Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight;
  }
  .Toastify__bounce-exit--bottom-center {
    animation-name: Toastify__bounceOutDown;
  }
  
  @keyframes Toastify__slideInRight {
    from {
      transform: translate3d(110%, 0, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes Toastify__slideOutRight {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      visibility: hidden;
      transform: translate3d(110%, 0, 0);
    }
  }
  @keyframes Toastify__slideOutDown {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      visibility: hidden;
      transform: translate3d(0, 500px, 0);
    }
  }
  .Toastify__slide-enter--bottom-right {
    animation-name: Toastify__slideInRight;
  }

  .Toastify__slide-exit--bottom-right {
    animation-name: Toastify__slideOutRight;
  }
  .Toastify__slide-exit--bottom-center {
    animation-name: Toastify__slideOutDown;
  }
  
  /*# sourceMappingURL=ReactToastify.css.map */
`

export default GlobalStyle
