import { nl } from './nl'
import { en } from './en'

interface ILocales {
  [key: string]: {
    [key: string]: string
  }
}

const locales: ILocales = {
  nl,
  en,
}

export const DEFAULT_LOCALE = 'nl'

export {
  nl as defaultLocaleStrings
}

export default locales
