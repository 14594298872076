import { useMediaQuery } from 'usehooks-ts'
import { StyledToastContainer } from './style'
import { useTheme } from 'styled-components'

const ToastMessageContainer = () => {
  const theme = useTheme()
  const isLarge = useMediaQuery(`(min-width: ${theme.LAYOUT.BREAKPOINT.SM}px)`)
  return (
    <StyledToastContainer
      position="bottom-right"
      autoClose={false}
      newestOnTop
      icon={false}
      closeOnClick={false}
      draggableDirection="y"
      draggablePercent={60}
      draggable={!isLarge}
    />
  )
}

export default ToastMessageContainer
