import SwitchLanguageButton from '@components/switch-language-button'
import LogoutButton from '@components/logout-button'
import useUserStore from '@hooks/use-user-store'
import IconNames from '@components/icon/icon-names'
import { StyledTextLink } from './style'
import { PAGES } from '@const/index'
import useF from '@hooks/use-f'
import { AccountScope } from '@store/user'

interface Props {
  className?: string
}

function SecondaryMenu ({
  className
}: Props) {
  const f = useF()
  const user = useUserStore().useUser()

  return (
    <nav className={className}>
      {user.access_token && <>
      {user.scope === AccountScope.PERMIT_HOLDER && <StyledTextLink label={`${user?.initials || ''} ${user?.family_name || ''}`} icon={IconNames.PERSONAL_LOGIN} link={{ href: PAGES.ACCOUNT_SETTINGS }} /> }
      {user.scope === AccountScope.VISITOR && <StyledTextLink label={f('visitor.account.name')} icon={IconNames.PERSONAL_LOGIN} />}
      </>}
      <StyledTextLink as={SwitchLanguageButton} />
      {user.access_token && <StyledTextLink as={LogoutButton} />}
    </nav>
  )
}

export default SecondaryMenu
