import {
  StyledContainer,
  StyledDescription,
  StyledIcon,
  StyledTextContainer,
  StyledTitle
} from './style'
import IconNames from '@components/icon/icon-names'
import { ReactNode, useState } from 'react'
import COLOR from '@theme/basic/color'
import { HeadingTypes } from '@theme/basic/typography'
import { useTimeout } from 'usehooks-ts'

export interface MessageProps {
  timeout?: number
  title?: string
  children?: ReactNode
  icon?: IconNames
  color?: string
}

const Message = ({
  timeout,
  title,
  children,
  icon,
  color = COLOR.SUPPORT_INVALID,
  ...props
}: MessageProps) => {
  const [ isVisible, setIsVisible ] = useState<boolean>(true)

  useTimeout(() => setIsVisible(!timeout), !timeout ? 0 : timeout)

  return isVisible
    ? (
    <StyledContainer color={color} {...props} role="alert" aria-labelledby={title ? 'message-title' : 'message-description'}>
      <StyledIcon name={icon || IconNames.ALERT} />
      <StyledTextContainer>
        {title
          ? (
          <StyledTitle id="message-title" type={HeadingTypes.HEADING_4}>{title}</StyledTitle>
            )
          : null}
        <StyledDescription id="message-description">{children}</StyledDescription>
      </StyledTextContainer>
    </StyledContainer>
      )
    : null
}

export default Message
