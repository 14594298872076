import {
  FooterBottom, FooterBottomLink, FooterTop, FooterTopLink, FooterTopList, FooterTopParagraph
} from './style'
import {
  Column, Container, Row
} from '@components/layout'
import Accordion from '@components/accordion/accordion'
import AccordionItem, { initialAccordionItemStates } from '@components/accordion/accordion-item'
import useF from '@hooks/use-f'
import useMediaQuery from '@hooks/use-media-query'
import IconNames from '@components/icon/icon-names'
import { useTheme } from 'styled-components'
import { HeadingTypes } from '@theme/basic/typography'

const Footer = () => {
  const f = useF()
  const { LAYOUT } = useTheme()
  const isLarge = useMediaQuery(`(min-width: ${LAYOUT.BREAKPOINT.MD}px)`)

  return (
    <footer>
      <FooterTop>
        <Container>
          <Row>
            <Column columns={{ SM: 12, MD: 4 }}>
              <Accordion>
                <AccordionItem isActive={!isLarge} initialState={initialAccordionItemStates.CLOSED} title={f('footer.contact.title')} headingLevel={HeadingTypes.HEADING_2}>
                  <FooterTopParagraph>
                    {f('footer.contact.content.1', {
                      link: <FooterTopLink isInline={true} isInverted={true} link={{ href: f('footer.contact.content.1.link.href'), isExternal: true, openInNewWindow: true }} label={f('footer.contact.content.1.link.label')} />
                    })}
                  </FooterTopParagraph>
                  <FooterTopParagraph>
                    {f('footer.contact.content.2')}
                  </FooterTopParagraph>
                  <FooterTopList>
                    <li>
                      <FooterTopLink isInline={true} isInverted={true} link={{ href: f('footer.contact.link.url.1'), isExternal: true, openInNewWindow: true }} label={f('footer.contact.link.label.1')} />
                    </li>
                    <li>
                      <FooterTopLink isInline={true} isInverted={true} link={{ href: f('footer.contact.link.url.2'), isExternal: true, openInNewWindow: true }} label={f('footer.contact.link.label.2')} />
                    </li>
                  </FooterTopList>
                </AccordionItem>
              </Accordion>
            </Column>
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <FooterBottomLink icon={IconNames.CHEVRON_RIGHT} isSecondary={true} link={{ href: f('footer.bottom.link.url.1'), isExternal: true, openInNewWindow: true }} label={f('footer.bottom.link.label.1')} />
          <FooterBottomLink icon={IconNames.CHEVRON_RIGHT} isSecondary={true} link={{ href: f('footer.bottom.link.url.2'), isExternal: true, openInNewWindow: true }} label={f('footer.bottom.link.label.2')} />
          <FooterBottomLink icon={IconNames.CHEVRON_RIGHT} isSecondary={true} link={{ href: f('footer.bottom.link.url.3'), isExternal: true, openInNewWindow: true }} label={f('footer.bottom.link.label.3')} />
        </Container>
      </FooterBottom>
    </footer>
  )
}

export default Footer
