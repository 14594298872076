import { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { BreakPoints } from '@utils/types'

const useMediaQuery = (query: string, defaultValue = false) => {
  const [ matches, setMatches ] = useState(defaultValue)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [ matches, query ])

  return matches
}

const useIsLG = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.BREAKPOINT.LG}px)`)
}

const useIsMD = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.BREAKPOINT.MD}px)`)
}

const useIsSM = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.BREAKPOINT.SM}px)`)
}

const useIsXS = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.BREAKPOINT.XS}px)`)
}

const useIsXXS = () => {
  const theme = useTheme()
  return useMediaQuery(`(min-width: ${theme.LAYOUT.BREAKPOINT.XXS}px)`)
}

function useCurrentBreakpoint (defaultValue?: keyof BreakPoints): keyof BreakPoints {
  const isLG = useIsLG()
  const isMD = useIsMD()
  const isSM = useIsSM()
  const isXS = useIsXS()
  const isXXS = useIsXXS()

  if (isLG) {
    return 'LG'
  }

  if (isMD) {
    return 'MD'
  }

  if (isSM) {
    return 'SM'
  }

  if (isXS) {
    return 'XS'
  }

  if (isXXS) {
    return 'XXS'
  }

  return defaultValue || 'XXS'
}

function useBreakpointBasedValue<T extends number | string> (
  breakpoints: { [key in keyof BreakPoints]: T },
  defaultValue?: keyof BreakPoints
) {
  const currentBreakpoint = useCurrentBreakpoint(defaultValue)

  const value = breakpoints[currentBreakpoint] || breakpoints.XXS

  return value
}

export {
  useCurrentBreakpoint,
  useBreakpointBasedValue,
  useIsLG,
  useIsMD,
  useIsSM,
  useIsXS,
  useIsXXS
}

export default useMediaQuery
