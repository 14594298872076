import {
  StyledPageHeader,
  StyledHeading,
  StyledIcon,
  StyledInner,
  StyledLink,
  StyledLogo,
  StyledLogoText,
  StyledBottom,
  StyledSecondaryMenu,
  StyledContainer, SkipLink
} from './style'
import IconNames from '@components/icon/icon-names'
import useF from '@hooks/use-f'
import { PAGES } from '@const/index'
import PrimaryMenu from '@components/primary-menu'

const Header = () => {
  const f = useF()

  return (
    <StyledPageHeader>
      <SkipLink link={{ href: '#main-content' }}>{f('skip-link.label')}</SkipLink>
      <StyledContainer>
        <StyledInner>
          <h1>
            <StyledLink link={{ href: PAGES.SITE_ROOT }}>
              <StyledLogo>
                <StyledIcon name={IconNames.ANDREAS} />
                <StyledLogoText>
                  <span>Gemeente</span>
                  <span>Amsterdam</span>
                </StyledLogoText>
              </StyledLogo>
              <StyledHeading>{f('header.title')}</StyledHeading>
            </StyledLink>
          </h1>
          <StyledSecondaryMenu />
        </StyledInner>
      </StyledContainer>
      <StyledBottom>
        <PrimaryMenu />
      </StyledBottom>
    </StyledPageHeader>
  )
}

export default Header
