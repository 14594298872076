import styled, { css } from 'styled-components'
import { ParagraphTypes } from '@theme/basic/typography'

interface IParagraphProps {
  $type?: ParagraphTypes,
}

const StyledParagraph = styled.p<IParagraphProps>(
  ({ $type = ParagraphTypes.PARAGRAPH_1, theme }) => css`
    ${$type && theme.TYPOGRAPHY[$type]};
  `
)

export default StyledParagraph
