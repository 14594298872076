import styled from 'styled-components'

export const Span = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.COLOR.COLOR_BLACK};
  fill: currentColor;
  line-height: 1;

  svg {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
`
