import Head from 'next/head'
import React, { ReactNode } from 'react'
import { DEFAULT_PAGE_TITLE } from '@const/index'
import useF from '@hooks/use-f'

interface IProps {
  pageName?: string
  children?: ReactNode
}

const DefaultTemplate = ({
  pageName,
  children,
}: IProps) : JSX.Element => {
  const f = useF()
  const seoTitle = f('seo-title', { page: f(`page.${pageName}`) })
  const seoDescription = f('seo-description')

  return (
    <>
      <Head>
        <title>{seoTitle || DEFAULT_PAGE_TITLE}</title>
        <meta name="description" content={seoDescription} />
      </Head>
      <main id="main-content" tabIndex={-1}>
        {children}
      </main>
    </>
  )
}

export default DefaultTemplate
