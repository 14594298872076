import DEFAULT_PAGE_TITLE from './default-page-title'
import { LICENSE_PLATE_FAVORITE_LIMIT } from './license-plate-favorite-limit'
import PAGES from './pages'
import SESSION_STATUSES from './session-statusses'

export {
  DEFAULT_PAGE_TITLE,
  PAGES,
  SESSION_STATUSES,
  LICENSE_PLATE_FAVORITE_LIMIT
}
export const MAXIMUM_PARKING_SESSIONS_ON_OVERVIEW = 10
