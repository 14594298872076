import create from 'zustand'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'
import getStore, { IToastStore, IToastsState } from '@store/toasts'

export const initToastsStore = (preloadedState?:IToastsState) => {
  return create<IToastStore>(getStore(preloadedState))
}

export const useCreateToastsStore = (initialState?:IToastsState) => {
  const state = typeof initialState === 'string' ? JSON.parse(initialState) : initialState
  const _store = initToastsStore(state)

  useIsomorphicLayoutEffect(() => {
    _store.getState().hydrate(state)
  }, [ _store, state ])

  return _store
}

export default useCreateToastsStore
