import styled, { css } from 'styled-components'
import { HeadingTypes } from '@theme/basic/typography'

interface IHeadingProps {
  $type?: HeadingTypes
}

const StyledHeading = styled.h1<IHeadingProps>(
  ({ $type, theme: { TYPOGRAPHY } }) => css`
      ${$type && TYPOGRAPHY[$type]};
    `
)

export default StyledHeading
