import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import {
  useContext
} from 'react'
import { StoreContext } from '@utils/zustand/store-provider'
import {
  IParkingSessionsStore
} from '@store/parking-sessions'
import { fullState } from '@store/parking-sessions/selectors'
import { initParkingSessionsStore } from '@hooks/use-create-parking-sessions-store'
import usePermitsStore from '@hooks/use-permits-store'

const useParkingSessionsStore = () => {
  const storeFromContext = useContext(StoreContext)?.['parking-sessions']
  const store = storeFromContext || initParkingSessionsStore()
  const autoGeneratedSelectorHooks = createSelectorHooks<IParkingSessionsStore>(store)
  const parkingSessions = autoGeneratedSelectorHooks()?.parkingSessions || []
  const useActiveParkingSessions = () => (parkingSessions.length > 0) ? parkingSessions.filter(({ status }) => status === 'Actief') : []
  const usePastParkingSessions = () => (parkingSessions.length > 0) ? parkingSessions.filter(({ status }) => status === 'Voltooid') : []
  const usePlannedParkingSessions = () => (parkingSessions.length > 0) ? parkingSessions.filter(({ status }) => status === 'Gepland').sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()) : []
  const filteredPermits = usePermitsStore().useFilteredPermits() || []
  const reportCodes = filteredPermits.map(({ reportCode }) => reportCode)

  const useFilteredActiveParkingSessions = () => {
    return (parkingSessions?.length > 0) ? parkingSessions?.filter(({ status, reportCode }) => status === 'Actief' && reportCodes?.includes(reportCode || 0)) : []
  }

  const useFilteredPastParkingSessions = () => {
    return (parkingSessions?.length > 0) ? parkingSessions.filter(({ status, reportCode }) => status === 'Voltooid' && reportCodes?.includes(reportCode || 0)) : []
  }
  const useFilteredPlannedParkingSessions = () => {
    return (parkingSessions?.length > 0) ? parkingSessions.filter(({ status, reportCode }) => status === 'Gepland' && reportCodes?.includes(reportCode || 0)).sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()) : []
  }

  const useParkingSessionsById = (id: string) => {
    return parkingSessions?.find(({ psRightId }) => Number(psRightId) === Number(id))
  }

  const hasActiveOrPlannedParkingSessions = () => {
    return (parkingSessions?.length > 0)
      ? parkingSessions?.filter(({
        status,
        reportCode
      }) => (status === 'Actief' || status === 'Gepland') && reportCodes?.includes(reportCode || 0)).length > 0
      : false
  }

  return {
    ...autoGeneratedSelectorHooks,
    fullState: store(fullState),
    useActiveParkingSessions,
    usePastParkingSessions,
    usePlannedParkingSessions,
    useFilteredActiveParkingSessions,
    useFilteredPastParkingSessions,
    useFilteredPlannedParkingSessions,
    hasParkingSessions: hasActiveOrPlannedParkingSessions,
    useParkingSessionsById
  }
}

export default useParkingSessionsStore
