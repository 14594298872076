import { DefaultTheme } from 'styled-components'
import ANIMATION from './basic/animation'
import FONT from './basic/font'
import COLOR from './basic/color'
import TYPOGRAPHY from './basic/typography'
import LAYOUT from './basic/layout'
import Z_INDEX from './z-index'
import TRANSITION from './transition'

const THEME: DefaultTheme = {
  ANIMATION,
  COLOR,
  FONT,
  TYPOGRAPHY,
  LAYOUT,
  Z_INDEX,
  TRANSITION
}

export default THEME
