import useF from '@hooks/use-f'
import { useRouter } from 'next/router'
import IconNames from '@components/icon/icon-names'
import { signOut } from 'next-auth/react'
import { PAGES } from '@const/index'
import useUserStore from '@hooks/use-user-store'
import TextLink from '@components/text-link'
import usePermitsStore from '@hooks/use-permits-store'
import useParkingSessionsStore from '@hooks/use-parking-sessions-store'
import useTransactionsStore from '@hooks/use-transactions-store'
import useLicensePlatesStore from '@hooks/use-license-plates-store'

interface Props {
  className?: string
  shouldShowIcon?: boolean
}

const LogoutButton = ({
  className,
  shouldShowIcon = true
}: Props) => {
  const f = useF()
  const router = useRouter()
  const clearUser = useUserStore().useClear()
  const clearPermits = usePermitsStore().useClear()
  const clearParkingSessionData = useParkingSessionsStore().useClear()
  const clearTransactions = useTransactionsStore().useClear()
  const clearLicencePlates = useLicensePlatesStore().useClear()

  const handleLogoutButtonClick = () => {
    (async () => {
      await signOut({ redirect: false })
      await router.push(PAGES.LOGIN)

      clearPermits()
      clearParkingSessionData()
      clearUser()
      clearTransactions()
      clearLicencePlates()
    })()
  }

  return (
    <TextLink label={f('menu.sign-out')} isSecondary={true} icon={shouldShowIcon ? IconNames.LOGOUT : undefined} onClick={handleLogoutButtonClick} className={className} />
  )
}

export default LogoutButton
