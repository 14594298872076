import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { useContext } from 'react'
import { StoreContext } from '@utils/zustand/store-provider'
import { ITransactionsStore } from '@store/transactions'
import { fullState } from '@store/transactions/selectors'
import { initTransactionsStore } from '@hooks/use-create-transactions-store'

const useTransactionsStore = () => {
  const storeFromContext = useContext(StoreContext)?.transactions
  const store = storeFromContext || initTransactionsStore()
  const autoGeneratedSelectorHooks = createSelectorHooks<ITransactionsStore>(store)

  return {
    ...autoGeneratedSelectorHooks,
    fullState: store(fullState)
  }
}

export default useTransactionsStore
