const COLOR = {
  // Primary Colours
  PRIMARY_RED: '#EC0000',
  PRIMARY_RED_HOVER: '#bc0000',
  PRIMARY_DARKBLUE: '#004699',
  PRIMARY_DARKBLUE_HOVER: '#00387a',
  PRIMARY_WHITE: '#fff',
  PRIMARY_BLACK: '#000',

  // Neutral Colours
  NEUTRAL_GREY_1: '#F5F5F5',
  NEUTRAL_GREY_2: '#E6E6E6',
  NEUTRAL_GREY_3_HOVER: '#909090',
  NEUTRAL_GREY_3: '#B4B4B4',
  NEUTRAL_GREY_4: '#767676',
  NEUTRAL_GREY_5: '#323232',

  // Support Colours
  SUPPORT_VALID: '#00A03C',
  SUPPORT_INVALID: '#EC0000',
  SUPPORT_FOCUS: '#FEC813',

  // Transparent Colours
  PRIMARY_BLACK_TRANSPARENT: '#E8E8E8',
  SUPPORT_VALID_TRANSPARENT: '#E8F6ED',
  SUPPORT_INVALID_TRANSPARENT: '#FDE8E8',
  PRIMARY_DARKBLUE_TRANSPARENT: '#E8EEF6',

  // Supplemental Colours
  SUPPLEMENT_ORANGE: '#FF9100',
  SUPPLEMENT_YELLOW: '#FFE600',
  SUPPLEMENT_LIGHTGREEN: '#BED200',
  SUPPLEMENT_DARKGREEN: '#00A03C',
  SUPPLEMENT_LIGHTBLUE: '#009DEC',
  SUPPLEMENT_PURPLE: '#A00078',
  SUPPLEMENT_PINK: '#E50082',

  // Other Colours
  OVERLAY_BLACK: 'rgba(0,0,0,0.7)'
}

export default COLOR
