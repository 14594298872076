import { ReactNode } from 'react'
import { ParagraphTypes } from '@theme/basic/typography'
import StyledParagraph from './style'

export interface IParagraphProps {
  type?: ParagraphTypes
  children?: ReactNode | string
  as?: React.ElementType
  id?: string
}

function Paragraph ({
  type, children, ...props
}: IParagraphProps) {
  return <StyledParagraph $type={type} {...props }>{children}</StyledParagraph>
}

export default Paragraph
