import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { ThemeProvider } from 'styled-components'
import { SessionProvider } from 'next-auth/react'
import { THEME, GlobalStyle } from '../theme'
import { IntlProvider } from 'react-intl'
import locales, { DEFAULT_LOCALE } from '@const/i18n'
import { IUserState } from '@store/user'
import { IPermitsState } from '@store/permits'
import { ITransactionsState } from '@store/transactions'
import { IParkingSessionsState } from '@store/parking-sessions'
import { ILicensePlatesState } from '@store/license-plates'
import { Session } from 'next-auth'
import { useRouter } from 'next/router'
import Footer from '@components/footer'
import Header from '@components/header'
import { StoreProvider } from '@utils/zustand/store-provider'
import useCreateUserStore from '@hooks/use-create-user-store'
import useCreatePermitsStore from '@hooks/use-create-permits-store'
import useCreateParkingSessionsStore from '@hooks/use-create-parking-sessions-store'
import useCreateLicensePlatesStore from '@hooks/use-create-license-plates-store'
import useCreateTransactionsStore from '@hooks/use-create-transactions-store'
import { LazyMotion, domAnimation } from 'framer-motion'
import OfflineMessage from '@components/offlineMessage'
import App, { AppContext } from 'next/app'
import { SpeedInsights } from '@vercel/speed-insights/next'
import ToastMessageContainer from '@components/toastMessageContainer'
import useCreateToastsStore from '@hooks/use-create-toasts-store'
import { IToastsState } from '@store/toasts'

const ErrorPage = dynamic(import('./_error'))

export interface IProps {
  Component: React.FC
  pageProps: {
    initialUserState: IUserState
    initialPermitsState: IPermitsState
    initialParkingSessionsState: IParkingSessionsState
    initialLicensePlatesState: ILicensePlatesState
    initialTransactionsState: ITransactionsState
    initialToastsState: IToastsState
    session: Session
    redirectURI: string
  }
  skipOfflineMode: string
  error: Record<string, unknown>
}

const MyApp = ({
  Component,
  pageProps: {
    session,
    redirectURI,
    initialUserState,
    initialPermitsState,
    initialParkingSessionsState,
    initialLicensePlatesState,
    initialTransactionsState,
    initialToastsState,
    ...pageProps
  },
  skipOfflineMode,
  error,
}: IProps): JSX.Element => {
  const router = useRouter()

  const { locale: localeFromRouter, defaultLocale } = router
  const locale = (localeFromRouter || defaultLocale) as string
  const messages = locales[locale]
  const userStore = useCreateUserStore(initialUserState)
  const permitsStore = useCreatePermitsStore(initialPermitsState)
  const parkingSessionsStore = useCreateParkingSessionsStore(
    initialParkingSessionsState
  )
  const licensePlatesStore = useCreateLicensePlatesStore(
    initialLicensePlatesState
  )
  const transactionsStore = useCreateTransactionsStore(initialTransactionsState)
  const toastsStore = useCreateToastsStore(initialToastsState)

  const stores = {
    user: userStore,
    permits: permitsStore,
    'parking-sessions': parkingSessionsStore,
    licensePlates: licensePlatesStore,
    transactions: transactionsStore,
    toasts: toastsStore
  }

  useEffect(() => {
    if (redirectURI) {
      router.replace(redirectURI)
    }
  }, [ redirectURI ])

  return (
    <>
      <LazyMotion features={domAnimation}>
        <StoreProvider stores={stores}>
          <SessionProvider session={session}>
            <IntlProvider
              locale={locale}
              timeZone={'Europe/Amsterdam'}
              defaultLocale={DEFAULT_LOCALE}
              messages={messages}
            >
              <GlobalStyle />

              <ThemeProvider theme={THEME}>
                <ToastMessageContainer/>
                <Header />
                {process.env.NEXT_PUBLIC_APP_OFFLINE === 'true' &&
                skipOfflineMode !== 'true'
                  ? (
                  <OfflineMessage />
                    )
                  : error
                    ? (
                  <ErrorPage {...error} />
                      )
                    : (
                  <Component {...pageProps} />
                      )}
                <Footer />
              </ThemeProvider>
            </IntlProvider>
          </SessionProvider>
        </StoreProvider>
      </LazyMotion>
      <SpeedInsights sampleRate={30} />
    </>
  )
}

MyApp.getInitialProps = async (context: AppContext) => {
  const initialProps = await App.getInitialProps(context)
  const skipOfflineMode =
    typeof window === 'undefined'
      ? context.ctx.req?.headers?.cookie
        ?.match('(^|;)\\s*' + 'skipOfflineMode' + '\\s*=\\s*([^;]+)')
        ?.pop()
      : window.document.cookie
        .match('(^|;)\\s*' + 'skipOfflineMode' + '\\s*=\\s*([^;]+)')
        ?.pop() || ''
  return {
    ...initialProps,
    skipOfflineMode,
  }
}

export default MyApp
