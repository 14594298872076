import React from 'react'
import DefaultTemplate from '@templates/default'
import UseF from '@hooks/use-f'
import Heading from '@components/typography/heading'
import Paragraph from '@components/typography/paragraph'
import {
  Column, Container, Row
} from '@components/layout'
import styled from 'styled-components'

const UnsupportedRegion = (): JSX.Element => {
  const f = UseF()

  return (
    <DefaultTemplate pageName="unsupported-region">
      <Container>
        <Row>
          <Column columns={{ SM: 12, MD: 12, LG: 12 }}>
            <SectionContainer>
            <Heading>{f('unsupported-region.intro.title')}</Heading>
            <Paragraph>{f('unsupported-region.intro.subtitle')}</Paragraph>
            </SectionContainer>
          </Column>
        </Row>
      </Container>
    </DefaultTemplate>
  )
}

export default UnsupportedRegion

const SectionContainer = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;
min-height: 18rem;
margin: 4rem 0 8rem 0;
`
