import IconNames from '@components/icon/icon-names'
import { Container, Row } from '@components/layout'
import Message from '@components/message'
import useF from '@hooks/use-f'
import { HeadingTypes } from '@theme/basic/typography'
import { StyledHeading, StyledColumn } from './style'
import { useTheme } from 'styled-components'

const OfflineMessage = () => {
  const { COLOR } = useTheme()
  const f = useF()
  return (
    <main>
    <Container>
      <Row>
        <StyledColumn columns={{ SM: 8, MD: 6 }} offset={{ SM: 2, MD: 3 }}>
        <StyledHeading type={HeadingTypes.HEADING_2}>{f('login.title')}</StyledHeading>

        <Message icon={IconNames.ALERT} color={COLOR.SUPPORT_INVALID} title={f('offline-title')}>
        {f('offline-message')}
        </Message>
        </StyledColumn>
      </Row>
    </Container>
  </main>
  )
}

export default OfflineMessage

