import {
  StyledContainer,
  StyledHamburgerButton,
  StyledLogoutButtonWrapper,
  StyledMainNav,
  StyledMenu,
  StyledNav,
  StyledOverlay,
  StyledParagraph,
  StyledSecondaryTextLink,
  StyledTextLink,
  StyledIcon
} from './style'

import IconNames from '@components/icon/icon-names'
import {
  useEffect, useRef, useState
} from 'react'
import useF from '@hooks/use-f'
import { MAXIMUM_PARKING_SESSIONS_ON_OVERVIEW, PAGES } from '@const/index'
import { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import SwitchLanguageButton from '@components/switch-language-button'
import LogoutButton from '@components/logout-button'
import useUserStore from '@hooks/use-user-store'
import { ParagraphTypes } from '@theme/basic/typography'
import { AccountScope } from '@store/user'
import usePermitsStore from '@hooks/use-permits-store'
import useMediaQuery from '@hooks/use-media-query'

const PrimaryMenu = () => {
  const { LAYOUT } = useTheme()
  const router = useRouter()
  const user = useUserStore().useUser()
  const permits = usePermitsStore().usePermits()
  const maximumAllowedSimultaneousParkingSessions = usePermitsStore().useMaximumAllowedSimultaneousParkingSessions()
  const f = useF()
  const [ isMenuOpen, setIsMenuOpen ] = useState(false)
  const visitorAccountAllowed = permits?.some(perm => perm?.visitorAccountAllowed)
  const favouritesAllowed = maximumAllowedSimultaneousParkingSessions <= MAXIMUM_PARKING_SESSIONS_ON_OVERVIEW
  const isLarge = useMediaQuery(`(min-width: ${LAYOUT.BREAKPOINT.MD}px)`)

  const toggleNav = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    const handleRouteChange = () => {
      setIsMenuOpen(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [])

  useEffect(() => {
    if (isLarge) {
      setIsMenuOpen(false)
    }
  }, [ isLarge ])

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isMenuOpen) {
      const menuElement = menuRef.current
      const focusableElements = menuElement?.querySelectorAll<HTMLButtonElement | HTMLAnchorElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      const firstElement = focusableElements?.[0]
      const lastElement = focusableElements?.[focusableElements?.length - 1]

      const handleTabKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault()
            lastElement?.focus()
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault()
            firstElement?.focus()
          }
        }
      }

      const handleEscapeKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setIsMenuOpen(false)
        }
      }

      menuElement?.addEventListener('keydown', handleTabKeyPress)
      menuElement?.addEventListener('keydown', handleEscapeKeyPress)

      return () => {
        menuElement?.removeEventListener('keydown', handleTabKeyPress)
        menuElement?.removeEventListener('keydown', handleEscapeKeyPress)
      }
    }
  }, [ isMenuOpen, setIsMenuOpen ])

  return (
    <>
      <StyledOverlay $isActive={isMenuOpen} />
      <StyledContainer>
        <StyledHamburgerButton
          aria-label={
            isMenuOpen ? f('menu.close.aria-label') : f('menu.open.aria-label')
          }
          onClick={() => {
            toggleNav()
          }}
        >
          <StyledIcon name={isMenuOpen ? IconNames.CLOSE : IconNames.MENU} />
        </StyledHamburgerButton>
        <StyledMenu $isOpen={isMenuOpen} ref={menuRef}>
          {user.access_token && (
            <>
              <StyledNav>
                <StyledLogoutButtonWrapper>
                  <StyledTextLink as={LogoutButton} />
                    <StyledParagraph
                      type={ParagraphTypes.PARAGRAPH_SUBTEXT}
                    >{`${user.initials} ${user.family_name}`}</StyledParagraph>
                </StyledLogoutButtonWrapper>
              </StyledNav>
              {user.scope === AccountScope.PERMIT_HOLDER && (
                <StyledMainNav>
                  <StyledSecondaryTextLink
                    isSecondary={true}
                    label={f('page.root')}
                    link={{ href: PAGES.SITE_ROOT }}
                  />
                   { (maximumAllowedSimultaneousParkingSessions > MAXIMUM_PARKING_SESSIONS_ON_OVERVIEW)
                     ? <StyledSecondaryTextLink
                      isSecondary={true}
                      label={f('page.parking-sessions.active')}
                      link={{ href: `${PAGES.PARKING_SESSIONS}?status=Actief` }}
                    />
                     : <></>
                   }
                  <StyledSecondaryTextLink
                    isSecondary={true}
                    label={f('page.parking-sessions.planned')}
                    link={{ href: `${PAGES.PARKING_SESSIONS}?status=Toekomstig` }}
                  />
                  <StyledSecondaryTextLink
                    isSecondary={true}
                    label={f('page.transactions')}
                    link={{ href: PAGES.TRANSACTIONS }}
                  />
                  {
                    favouritesAllowed &&
                    <StyledSecondaryTextLink
                      isSecondary={true}
                      label={f('page.favorites')}
                      link={{ href: PAGES.FAVORITES }}
                    />
                  }
                 {visitorAccountAllowed && <StyledSecondaryTextLink
                    isSecondary={true}
                    label={f('page.visitor.account')}
                    link={{ href: PAGES.VISITOR_ACCOUNT_SETTINGS }}
                  />}
                 {isMenuOpen && <StyledSecondaryTextLink
                    isSecondary={true}
                    label={`Account ${f('page.account.settings')}`}
                    link={{ href: PAGES.ACCOUNT_SETTINGS }}
                  />}
                </StyledMainNav>
              )}
            </>
          )}
          <StyledNav>
            <StyledSecondaryTextLink
              as={SwitchLanguageButton}
              shouldShowIcon={false}
            />
          </StyledNav>
        </StyledMenu>
      </StyledContainer>
    </>
  )
}

export default PrimaryMenu
