import create from 'zustand/vanilla'
import { devtools } from 'zustand/middleware'
import { WritableDraft } from 'immer/dist/internal'
import immer from '@utils/zustand/immer-middleware'
import merge from 'lodash/merge'
import { StoreApi } from 'zustand'

export const STORE_NAME = 'permits'

type StoreSet = (fn: (draft: WritableDraft<IPermitsStore>) => void) => void

export enum PermitTypes {
  BEZOEKERSVERGUNNING = 'Bezoekersvergunning',
  BEZOEKERS_ZONDER_KORTING = 'Bezoekers zonder korting',
  MANTELZORGVERGUNNING = 'Mantelzorgvergunning',
  KRASKAARTVERGUNNING = 'Kraskaartvergunning',
  GEHANDICAPTEN_BEZOEKERSVERGUNNING = 'Gehandicapten bezoekersvergunning',
  GEHANDICAPTENVERGUNNING_VOOR_PASSAGIERS = 'Gehandicaptenvergunning voor passagiers',
  GA_PARKEERVERGUNNING_VOOR_BEWONERS_PASSAGIERS = 'GA-parkeervergunning voor bewoners (passagiers)',
  CODEVERGUNNING = 'Codevergunning'
}

export interface IDay {
  dayOfWeek: string
  startTime: string
  endTime: string
}

export interface IPaymentZone {
  id: string
  description: string
  city: string
  days: IDay[]
}

export interface IPermitZone {
  permitZoneId: string
  name: string
  description: string
  showPermitzoneUrl: boolean
}

export interface IParkingRate {
  currency: string
  value: number
}

export interface IVisitorAccount {
  millisecondsRemaining: number
  pin: string
  reportCode: number
}

export interface IPermit {
  reportCode: number
  timeBalance: number
  timeValidUntil: string
  permitZone: IPermitZone
  permitType: PermitTypes
  permitName: string
  parkingRate: IParkingRate
  parkingRateOriginal: IParkingRate
  discount: number
  paymentZones: IPaymentZone[]
  visitorAccount?: IVisitorAccount
  moneyBalanceApplicable: boolean
  timeBalanceApplicable: boolean
  forcedLicensePlateList: boolean
  noEndtime: boolean
  visitorAccountAllowed: boolean
  maxSessionsAllowed: number
  maxSessionLengthInDays: number
}
export interface IPrice {
  currency: string
  value: number
}

export interface IAddress {
  city: string
  concatenatedAddress: string
  houseNumber: number
  street: string
  zipCode: string
}

export interface IAccountDetails {
  clientId: number
  email: string
  initials: string
  lastName: string
  phoneNumber: string
  address: IAddress
}

export interface IPermitsState {
  permits: IPermit[]
  currentWalletBalance?: IPrice
  accountDetails?: IAccountDetails
}

export interface IPermitsStore extends IPermitsState {
  setPermits: (permits: IPermit[]) => void,
  setWalletBalance: (walletBalance: IPrice) => void,
  allocateCreditVisitorAccount: (reportCode: number, millisecondsMainPermit: number, milliSecondsVisitorAcount: number) => void,
  setAccountDetails: (accountDetails: IAccountDetails) => void,
  hydrate: (input: IPermitsState) => void,
  clear: () => void,
}

let store: StoreApi<IPermitsStore> | undefined

const initialState: IPermitsState = {
  permits: [],
  currentWalletBalance: {
    value: 0,
    currency: 'EUR'
  },
}

const createState =
  (preLoadedState?: IPermitsState) => (set: StoreSet, get: () => IPermitsState) => ({
    ...initialState,
    ...preLoadedState,
    setPermits: (permits: IPermit[]) =>
      set((state) => {
        state.permits = permits
      }),
    allocateCreditVisitorAccount: (reportCode: number, millisecondsMainPermit: number, milliSecondsVisitorAcount: number) => set((state) => {
      const permit = state.permits.find((permit) => permit.reportCode === reportCode)
      if (permit?.visitorAccount) {
        permit.timeBalance = millisecondsMainPermit
        permit.visitorAccount.millisecondsRemaining = milliSecondsVisitorAcount
      }

      return state
    }),
    setWalletBalance: (currentWalletBalance: IPrice) => set((state) => {
      state.currentWalletBalance = currentWalletBalance
    }),
    setAccountDetails: (accountDetails: IAccountDetails) => set((state) => {
      state.accountDetails = accountDetails
    }),
    hydrate: (input: IPermitsState) =>
      set((state: IPermitsState) => {
        if (state && input?.permits) {
          return merge({}, get(), input)
        }
      }),
    clear: () => set(() => initialState)
  })

const getStore = (preLoadedState?: IPermitsState) => {
  if (process.browser && store) {
    if (preLoadedState) {
      store.getState().hydrate(preLoadedState)
    }
    return store
  }

  store = create<IPermitsStore>(
    devtools(immer(createState(preLoadedState)), { name: STORE_NAME })
  )

  return store
}

export default getStore

