import create from 'zustand/vanilla'
import { devtools } from 'zustand/middleware'
import { WritableDraft } from 'immer/dist/internal'
import immer from '@utils/zustand/immer-middleware'
import merge from 'lodash/merge'
import { StoreApi } from 'zustand'

export const STORE_NAME = 'toast'

type StoreSet = (fn: (draft: WritableDraft<IToastStore>) => void) => void;

export interface IToastsState {
  toastIds: string[]
}

export interface IToastStore extends IToastsState {
  setToastIds: (toastIds: string[]) => void,
  addToastId: (toastId: string) => void,
  removeToastId: (toastId: string) => void,
  removeFirstToastId: () => void,
  hydrate: (input: IToastsState) => void,
  clear: () => void,
}
let store: StoreApi<IToastStore> | undefined

const initialState:IToastsState = {
  toastIds: []
}

const createState = (preLoadedState?: IToastsState) => (set: StoreSet, get: () => IToastsState) => ({
  ...initialState,
  ...preLoadedState,
  setToastIds: (toastIds: string[]) => set((state) => {
    state.toastIds = toastIds
  }),
  addToastId: (toastId: string) => set((state) => {
    state.toastIds = [ ...state.toastIds, toastId ]
  }),
  removeToastId: (toastId: string) => set((state) => {
    state.toastIds = state.toastIds.filter((id) => id !== toastId)
  }),
  removeFirstToastId: () => set((state) => {
    const toastIds = [ ...state.toastIds ]
    if (toastIds.length > 2) {
      state.toastIds = state.toastIds.slice(1)
    }
  }),
  hydrate: (input: IToastsState) => set((state: IToastsState) => {
    if (state && input?.toastIds) {
      return merge({}, get(), input)
    }
  }),
  clear: () => set(() => initialState),
})

const getStore = (preLoadedState?:IToastsState) => {
  if (process.browser && store) {
    if (preLoadedState) {
      store.getState().hydrate(preLoadedState)
    }
    return store
  }

  store = create<IToastStore>(
    devtools(
      immer(createState(preLoadedState)), { name: STORE_NAME }
    )
  )

  return store
}

export default getStore
