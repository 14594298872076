import React from 'react'
import { Span } from './style'
import IconNames from './icon-names'
import dynamic from 'next/dynamic'

export interface IIconProps {
  name: IconNames,
  className?: string
}

const iconMap = {
  [IconNames.ALERT]: dynamic(() => import('./svg/alert.svg')),
  [IconNames.ANDREAS]: dynamic(() => import('./svg/andreas.svg')),
  [IconNames.CAR]: dynamic(() => import('./svg/car.svg')),
  [IconNames.CHECKMARK]: dynamic(() => import('./svg/checkmark.svg')),
  [IconNames.CHEVRON_DOWN]: dynamic(() => import('./svg/chevron-down.svg')),
  [IconNames.CHEVRON_LEFT]: dynamic(() => import('./svg/chevron-left.svg')),
  [IconNames.CHEVRON_RIGHT]: dynamic(() => import('./svg/chevron-right.svg')),
  [IconNames.CHEVRON_UP]: dynamic(() => import('./svg/chevron-up.svg')),
  [IconNames.CLOSE]: dynamic(() => import('./svg/close.svg')),
  [IconNames.CLOSE_CIRCLE]: dynamic(() => import('./svg/close-circle.svg')),
  [IconNames.EDIT_DOCUMENT]: dynamic(() => import('./svg/edit-document.svg')),
  [IconNames.ENLARGE]: dynamic(() => import('./svg/enlarge.svg')),
  [IconNames.EUROCOIN]: dynamic(() => import('./svg/eurocoin.svg')),
  [IconNames.EXTERNAL_LINK]: dynamic(() => import('./svg/external-link.svg')),
  [IconNames.INFO]: dynamic(() => import('./svg/info.svg')),
  [IconNames.LOADER]: dynamic(() => import('./svg/loader.svg')),
  [IconNames.LOGOUT]: dynamic(() => import('./svg/logout.svg')),
  [IconNames.MAP]: dynamic(() => import('./svg/map.svg')),
  [IconNames.MENU]: dynamic(() => import('./svg/menu.svg')),
  [IconNames.PERSONAL_LOGIN]: dynamic(() => import('./svg/personal-login.svg')),
  [IconNames.SEARCH]: dynamic(() => import('./svg/search.svg')),
  [IconNames.SHARE]: dynamic(() => import('./svg/share.svg')),
  [IconNames.TRASH_BIN]: dynamic(() => import('./svg/trash-bin.svg')),
}

export default function Icon ({
  name,
  className
}: IIconProps): React.ReactElement {
  const IconComponent = iconMap[name] || iconMap[IconNames.CLOSE]

  return (
    <Span
      className={className}
    ><IconComponent /></Span>
  )
}
