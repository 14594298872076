import styled, { css } from 'styled-components'

type BreakPoints = {
  XXS?: number
  XS?: number
  SM?: number
  MD?: number
  LG?: number
}

export const Container = styled.div(
  ({ theme: { LAYOUT } }) => css`
    width: 100%;
    margin: 0 auto;
    max-width: ${LAYOUT.MAX_CONTAINER_WIDTH}px;

    ${Object.entries(LAYOUT.BREAKPOINT).map(
      ([ key, value ]) => css`
        @media screen and (min-width: ${value}px) {
          padding-left: ${LAYOUT.GRID_OFFSET[key as keyof BreakPoints]}px;
          padding-right: ${LAYOUT.GRID_OFFSET[key as keyof BreakPoints]}px;
        }
      `
    )}
  `
)

export const Row = styled.div(
  ({ theme: { LAYOUT } }) => css`
    display: flex;
    flex-wrap: wrap;

    ${Object.entries(LAYOUT.BREAKPOINT).map(
      ([ key, value ]) => css`
        @media screen and (min-width: ${value}px) {
          margin: calc(-${LAYOUT.GRID_GAP[key as keyof BreakPoints]}px / 2);
        }
      `
    )}
  `
)

interface StyledColumnProps {
  $columns: BreakPoints
  $columnOffset: BreakPoints
}

export const StyledColumn = styled.div<StyledColumnProps>(
  ({ theme: { LAYOUT }, $columns, $columnOffset }) => css`
    width: 100%;

    ${Object.entries(LAYOUT.BREAKPOINT).map(
      ([ key, value ]) => css`
        @media screen and (min-width: ${value}px) {
          padding: calc(${LAYOUT.GRID_GAP[key as keyof BreakPoints]}px / 2);
          width: ${$columns &&
          $columns[key as keyof BreakPoints] &&
          `calc(100% / ${LAYOUT.GRID_COLUMNS} * ${$columns[key as keyof BreakPoints]})`};
          margin-left: ${$columnOffset &&
          $columnOffset[key as keyof BreakPoints] &&
          `calc(100% / ${LAYOUT.GRID_COLUMNS} * ${$columnOffset[key as keyof BreakPoints]})`};
        }
      `
    )}
  `
)
