import { createContext } from 'react'

const defaultColumnValues = {
  breakpointValue: undefined,
  nextBreakpointWidth: undefined,
  maximumColumnWidth: undefined,
}

export interface IColumnContext {
  [key: string]: {
    breakpointValue: undefined | number
    nextBreakpointWidth: undefined | number
    maximumColumnWidth: undefined | number
  }
}

export default createContext({
  XXS: defaultColumnValues,
  XS: defaultColumnValues,
  SM: defaultColumnValues,
  MD: defaultColumnValues,
  LG: defaultColumnValues,
} as IColumnContext)
