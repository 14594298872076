import create from 'zustand'
import getStore, {
  IUserState,
  IUserStore
} from '@store/user'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

export const initUserStore = (preloadedState?:IUserState) => {
  return create<IUserStore>(getStore(preloadedState))
}

export const useCreateUserStore = (initialState?:IUserState) => {
  const state = typeof initialState === 'string' ? JSON.parse(initialState) : initialState
  const _store = initUserStore(state)

  useIsomorphicLayoutEffect(() => {
    _store.getState().hydrate(state)
  }, [ _store, state ])

  return _store
}

export default useCreateUserStore
