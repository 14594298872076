const ANIMATION = {
  FADE: {
    ease: [ 0.55, 0, 0.45, 1 ],
    duration: 0.4
  },
  SLIDE: {
    ease: [ 0.65, 0, 0.35, 1 ],
    duration: 0.3
  }
}

export default ANIMATION
