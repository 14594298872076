import styled from 'styled-components'
import Icon from '@components/icon'
import Link from '@components/link'
import { HeadingTypes } from '@theme/basic/typography'
import SecondaryMenu from '@components/secondary-menu'
import { Container } from '@components/layout'

export const StyledPageHeader = styled.header`
  z-index: ${({ theme }) => theme.Z_INDEX.PAGE_HEADER};
`

export const SkipLink = styled(Link)`
  position: fixed;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: ${({ theme: { Z_INDEX } }) => Z_INDEX.SKIP_LINK};
  
  &:focus {
    left: 0;
    width: auto;
    height: auto;
  }
`

export const StyledContainer = styled(Container)`
  background: white;
  position: relative;
  z-index: ${({ theme }) => theme.Z_INDEX.PAGE_HEADER};
`

export const StyledInner = styled.div`
  display: flex;
  padding: 1rem 0;
  justify-content: space-between;
  height: 8.6rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.SM}px) {
    padding: 1.6rem 0;
    height: 8.1rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    padding: 2rem 0;
    height: 10.8rem;
  }
`

export const StyledSecondaryMenu = styled(SecondaryMenu)`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    display: block;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.COLOR.PRIMARY_BLACK};
  display: flex;
`

export const StyledLogo = styled.span`
  display: flex;
  margin-right: 1.6rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    margin-right: 3.2rem;
  }
`

export const StyledIcon = styled(Icon)`
  height: 4rem;
  margin-right: 0.6rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    height: 6.6rem;
    margin-right: 1rem;
  }
`

export const StyledLogoText = styled.span`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  font-size: 1.4rem;
  line-height: 1;
  color: ${({ theme }) => theme.COLOR.PRIMARY_RED};
  font-weight: 600;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.BREAKPOINT.MD}px) {
    font-size: 2.3rem;
  }
`

export const StyledHeading = styled.span`
  ${({ theme }) => theme.TYPOGRAPHY[HeadingTypes.HEADING_4]};
  
  @media screen and (min-width: ${({ theme }) =>
    theme.LAYOUT.BREAKPOINT.MD}px) {
    ${({ theme }) => theme.TYPOGRAPHY[HeadingTypes.HEADING_1]};
  }
}
`

export const StyledBottom = styled.div`
  background-color: ${({ theme }) => theme.COLOR.NEUTRAL_GREY_1};
  min-height: 4.4rem;
`
