import { css } from 'styled-components'

export enum HeadingTypes {
  HEADING_1 = 'HEADING_1',
  HEADING_2 = 'HEADING_2',
  HEADING_3 = 'HEADING_3',
  HEADING_4 = 'HEADING_4',
}

export enum ParagraphTypes {
  PARAGRAPH_1 = 'PARAGRAPH_1',
  PARAGRAPH_2 = 'PARAGRAPH_2',
  PARAGRAPH_HIGHLIGHT = 'PARAGRAPH_HIGHLIGHT',
  PARAGRAPH_SUBTEXT = 'PARAGRAPH_SUBTEXT'
}

const HEADING_1 = css`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  font-weight: 600;
  font-style: normal;
  font-size: 2.4rem;
  line-height: 3.2rem;

  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.BREAKPOINT.LG}px) {
    font-size: 3rem;
    line-height: 4rem;
  }
`

const HEADING_2 = css`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  font-weight: 600;
  font-style: normal;
  font-size: 2rem;
  line-height: 2.8rem;

  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.BREAKPOINT.LG}px) {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`

const HEADING_3 = css`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  font-weight: 600;
  font-style: normal;
  font-size: 2rem;
  line-height: 2.8rem;
`

const HEADING_4 = css`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  font-weight: 600;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 2.4rem;
`

const PARAGRAPH_1 = css`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  font-weight: 400;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
`

const PARAGRAPH_2 = css`
  ${PARAGRAPH_1};

  @media screen and (min-width: ${({ theme }) => theme.LAYOUT.BREAKPOINT.LG}px) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`
const PARAGRAPH_HIGHLIGHT = css`
  ${PARAGRAPH_1};
  font-weight: 600;
`

const PARAGRAPH_SUBTEXT = css`
  ${PARAGRAPH_1};
  font-size: 1.4rem;
  line-height: 1.8rem;
`

const typography = {
  [HeadingTypes.HEADING_1]: HEADING_1,
  [HeadingTypes.HEADING_2]: HEADING_2,
  [HeadingTypes.HEADING_3]: HEADING_3,
  [HeadingTypes.HEADING_4]: HEADING_4,
  [ParagraphTypes.PARAGRAPH_1]: PARAGRAPH_1,
  [ParagraphTypes.PARAGRAPH_2]: PARAGRAPH_2,
  [ParagraphTypes.PARAGRAPH_HIGHLIGHT]: PARAGRAPH_HIGHLIGHT,
  [ParagraphTypes.PARAGRAPH_SUBTEXT]: PARAGRAPH_SUBTEXT,
}

export default typography
