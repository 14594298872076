import styled, { css } from 'styled-components'
import Icon from '@components/icon'

interface IProps {
  $isInverted?: boolean
  $isInline?: boolean
  $isSecondary?: boolean
  [key: string]: unknown
}

export const StyledIcon = styled(Icon)`
  fill: ${({ theme }) => theme.COLOR.PRIMARY_BLACK};
  height: 1.6rem;
  display: flex;
`

export const StyledContainer = styled.button<IProps>`
  position: relative;
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  color: ${({ theme }) => theme.COLOR.PRIMARY_DARKBLUE};
  background-color: transparent;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  text-decoration: none;
  transition: ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};

  ${StyledIcon} {
    fill: ${({ theme }) => theme.COLOR.PRIMARY_DARKBLUE};
  }

  &:hover {
    color: ${({ theme }) => theme.COLOR.PRIMARY_DARKBLUE_HOVER};

    ${StyledIcon} {
      fill: ${({ theme }) => theme.COLOR.PRIMARY_DARKBLUE_HOVER};
    }
  }

  ${({ $isSecondary, theme }) =>
    $isSecondary &&
    css`
      color: ${theme.COLOR.PRIMARY_BLACK};

      ${StyledIcon} {
        fill: ${({ theme }) => theme.COLOR.PRIMARY_BLACK};
      }
      
      &:hover {
        color: ${({ theme }) => theme.COLOR.PRIMARY_RED};

        ${StyledIcon} {
          fill: ${({ theme }) => theme.COLOR.PRIMARY_RED};
        }
      }
    `}

  ${({ $isInline, $isInverted, theme }) =>
    $isInline &&
    css`
      color: ${theme.COLOR.PRIMARY_DARKBLUE};
      font-weight: 400;
      text-decoration: underline;

      ${StyledIcon} {
        fill: ${theme.COLOR.PRIMARY_DARKBLUE};
      }
      
      ${!$isInverted && css`
        &:visited {
          color: ${({ theme }) => theme.COLOR.SUPPLEMENT_PURPLE};

          ${StyledIcon} {
            fill: ${theme.COLOR.SUPPLEMENT_PURPLE};
          }
        }
      `}
    `}

  ${({ $isInverted, theme }) =>
    $isInverted &&
    css`
      color: ${theme.COLOR.PRIMARY_WHITE};

      ${StyledIcon} {
        fill: ${theme.COLOR.PRIMARY_WHITE};
      }
      
      &:hover {
        color: ${theme.COLOR.PRIMARY_WHITE};

        ${StyledIcon} {
          fill: ${theme.COLOR.PRIMARY_WHITE};
        }
      }
    `}

    ${({ isDisabled }) => isDisabled === true && css`
    color: ${({ theme }) => theme.COLOR.NEUTRAL_GREY_3};
    pointer-events: none;
    border: none;
    
    &:after {
      border-left-color: ${({ theme }) => theme.COLOR.NEUTRAL_GREY_1};
    }
    
    ${StyledIcon} {
      fill: ${({ theme }) => theme.COLOR.NEUTRAL_GREY_3};
    }
  `}

`
