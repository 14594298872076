import create from 'zustand/vanilla'
import { devtools } from 'zustand/middleware'
import { WritableDraft } from 'immer/dist/internal'
import immer from '@utils/zustand/immer-middleware'
import merge from 'lodash/merge'
import { StoreApi } from 'zustand'

export const STORE_NAME = 'transactions'

type StoreSet = (fn: (draft: WritableDraft<ITransactionsStore>) => void) => void

export interface ITransaction {
  orderType: 'Opwaarderen' | 'Sessie' | 'Restitutie'
  permitName: string
  reportCode: string
  amount: {
    value: number
    currency: 'EUR'
  },
  creationTime?: string,
  transactionDate: string
  paymentOption: 'iDeal' | 'AutomatischIncasso'
  transactionId: number
  client: string
  startDateTime: string
  endDateTime: string
  isVisitor: boolean
  isPaid: boolean
  parkingTime: number
  vehicleId: string
  isCancelled: boolean
  updatedTime?: string
  days: Array<{endTime: string, startTime: string, dayOfWeek: string}>
}

export interface ITransactionMeta {
  totalItems: number
  itemsPerPage: number
  pages: number
  currentPage: number
  pagination: {
    first: string
    prev?: string
    curr: string
    next?: string
    last: string
  }
}

export interface ITransactionsState {
  transactions: ITransaction[]
  meta: ITransactionMeta
}

export interface ITransactionsStore extends ITransactionsState {
  setTransactions: (transactions: ITransaction[]) => void
  setMeta: (meta: ITransactionMeta) => void
  hydrate: (input: ITransactionsState) => void
  clear: () => void
}

let store: StoreApi<ITransactionsStore> | undefined

const initialState: ITransactionsState = {
  transactions: [],
  meta: {
    currentPage: 1,
    itemsPerPage: 0,
    pages: 0,
    totalItems: 0,
    pagination: {
      curr: '',
      first: '',
      last: '',
      next: '',
      prev: ''
    }
  }
}

const createState =
  (preLoadedState?: ITransactionsState) => (set: StoreSet, get: () => ITransactionsState) => ({
    ...initialState,
    ...preLoadedState,

    setTransactions: (transactions: ITransaction[]) =>
      set((state) => {
        state.transactions = transactions
      }),

    setMeta: (meta: ITransactionMeta) =>
      set((state) => {
        state.meta = meta
      }),

    hydrate: (input: ITransactionsState) =>
      set((state: ITransactionsState) => {
        if (state && input?.transactions) {
          return merge({}, get(), input)
        }
      }),
    clear: () => set(() => initialState)
  })

const getStore = (preLoadedState?: ITransactionsState) => {
  if (process.browser && store) {
    if (preLoadedState) {
      store.getState().hydrate(preLoadedState)
    }
    return store
  }

  store = create<ITransactionsStore>(
    devtools(immer(createState(preLoadedState)), { name: STORE_NAME })
  )

  return store
}

export default getStore
