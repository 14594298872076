import { StyledTextLink } from './style'
import useF from '@hooks/use-f'
import { useRouter } from 'next/router'
import IconNames from '@components/icon/icon-names'

interface Props {
  className?: string
  shouldShowIcon?: boolean
}

const SwitchLanguageButton = ({
  className,
  shouldShowIcon = true
}: Props) => {
  const f = useF()
  const {
    pathname, asPath, query, push, locale
  } = useRouter()

  const handleLanguageSwitchClick = () => {
    push({ pathname, query }, asPath, { locale: locale === 'nl' ? 'en' : 'nl' })
  }

  return (
    <StyledTextLink label={f('header.switch-language')} isSecondary={true} icon={shouldShowIcon ? IconNames.CHEVRON_RIGHT : undefined} onClick={handleLanguageSwitchClick} className={className} />
  )
}

export default SwitchLanguageButton
