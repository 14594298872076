import create from 'zustand'
import getStore, {
  IPermitsState,
  IPermitsStore
} from '@store/permits'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'
export const initPermitsStore = (preloadedState?:IPermitsState) => {
  return create<IPermitsStore>(getStore(preloadedState))
}

export const useCreatePermitsStore = (initialState?:IPermitsState) => {
  const state = typeof initialState === 'string' ? JSON.parse(initialState) : initialState
  const _store = initPermitsStore(state)

  useIsomorphicLayoutEffect(() => {
    _store.getState().hydrate(state)
  }, [ _store, state ])

  return _store
}

export default useCreatePermitsStore
