import create from 'zustand/vanilla'
import { devtools } from 'zustand/middleware'
import { WritableDraft } from 'immer/dist/internal'
import immer from '@utils/zustand/immer-middleware'
import merge from 'lodash/merge'
import { StoreApi } from 'zustand'

export const STORE_NAME = 'licensePlates'

type StoreSet = (fn: (draft: WritableDraft<ILicensePlatesStore>) => void) => void;

export interface ILicensePlate {
  vehicleId: string,
  visitorName: string,
}

export interface IPermitLicensePlates {
  permitReportCode: number
  licensePlates: ILicensePlate[],
}

export interface ILicensePlatesState {
  permitsLicensePlates: IPermitLicensePlates[]
}

export interface ILicensePlatesStore extends ILicensePlatesState {
  setPermitLicensePlates: (permitsLicensePlates: IPermitLicensePlates[]) => void,
  updatePermitLicensePlates: (permitLicensePlates: IPermitLicensePlates) => void,
  addToPermitLicensePlates: (permitReportCode: number, licensePlate: ILicensePlate) => void,
  hydrate: (input: ILicensePlatesState) => void,
  clear: () => void,
}

let store: StoreApi<ILicensePlatesStore> | undefined

const initialState:ILicensePlatesState = {
  permitsLicensePlates: [],
}

const createState = (preLoadedState?: ILicensePlatesState) => (set: StoreSet, get: () => ILicensePlatesState) => ({
  ...initialState,
  ...preLoadedState,
  setPermitLicensePlates: (permitsLicensePlates: IPermitLicensePlates[]) => set((state) => {
    state.permitsLicensePlates = permitsLicensePlates
  }),
  updatePermitLicensePlates: (permitLicensePlates: IPermitLicensePlates) => set((state) => {
    state.permitsLicensePlates = state.permitsLicensePlates.map(perm => perm.permitReportCode === permitLicensePlates.permitReportCode ? permitLicensePlates : perm)
  }),
  addToPermitLicensePlates: (permitReportCode: number, licensePlate: ILicensePlate) => set((state) => {
    state.permitsLicensePlates = state.permitsLicensePlates.map(perm => perm.permitReportCode === permitReportCode ? { ...perm, licensePlates: [ ...perm.licensePlates, licensePlate ] } : perm)
  }),
  hydrate: (input: ILicensePlatesState) => set((state: ILicensePlatesState) => {
    if (state && input?.permitsLicensePlates) {
      return merge({}, get(), input)
    }
  }),
  clear: () => set(() => initialState),
})

const getStore = (preLoadedState?:ILicensePlatesState) => {
  if (process.browser && store) {
    if (preLoadedState) {
      store.getState().hydrate(preLoadedState)
    }
    return store
  }

  store = create<ILicensePlatesStore>(
    devtools(
      immer(createState(preLoadedState)), { name: STORE_NAME }
    )
  )

  return store
}

export default getStore
