import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import {
  useContext, useMemo
} from 'react'
import { StoreContext } from '@utils/zustand/store-provider'
import {
  IPermit,
  IPermitsStore
} from '@store/permits'
import { fullState } from '@store/permits/selectors'
import { initPermitsStore } from '@hooks/use-create-permits-store'
import { useRouter } from 'next/router'

const usePermitsStore = () => {
  const storeFromContext = useContext(StoreContext)?.permits
  const store = storeFromContext || initPermitsStore()
  const { query } = useRouter()
  const autoGeneratedSelectorHooks = createSelectorHooks<IPermitsStore>(store)
  const { permits } = autoGeneratedSelectorHooks()
  const hasOnlyOnePermit = () => (permits?.length === 1)
  const getVisitorPermit = () => (permits?.[0] || {})
  const useSortedPermits = (): IPermit[] => permits?.slice()
    .sort((permitA, permitB) => {
      if (permitA?.permitName < permitB?.permitName) {
        return -1
      }
      if (permitA.permitName > permitB.permitName) {
        return 1
      }
      return 0
    }) || []

  const useFilteredPermits = (): IPermit[] => {
    const filter = query.filter
    return useMemo(() => permits?.slice().filter(({ reportCode }) => !filter || filter === 'all' || filter === 'undefined' || `${reportCode}` === filter)
      .sort((permitA, permitB) => {
        if (permitA?.permitName < permitB?.permitName) {
          return -1
        }
        if (permitA.permitName > permitB.permitName) {
          return 1
        }
        return 0
      }), [ filter, permits ]) || []
  }

  const usePermitByReportCode = (reportCodeInput: number|undefined): IPermit | undefined => permits?.find(({ reportCode }) => reportCode === reportCodeInput)

  const useMaximumAllowedSimultaneousParkingSessions = () => permits?.reduce((acc, permit) => {
    acc += permit.maxSessionsAllowed
    return acc
  }, 0)

  return {
    ...autoGeneratedSelectorHooks,
    fullState: store(fullState),
    useSortedPermits,
    useFilteredPermits,
    hasOnlyOnePermit,
    usePermitByReportCode,
    getVisitorPermit,
    useMaximumAllowedSimultaneousParkingSessions,
  }
}

export default usePermitsStore
