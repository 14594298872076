import React, { ReactNode, MouseEvent } from 'react'
import NextLink from 'next/link'
import { ILink } from '@utils/types'
import { useRouter } from 'next/router'

export interface IProps{
  children?: ReactNode
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  link?: ILink
}

export default function Link ({
  link,
  onClick,
  ...props
}: IProps) : JSX.Element {
  const {
    isExternal = false,
    openInNewWindow,
    href = ''
  } = link || {}
  const { asPath } = useRouter()
  const modifiedHref = href.replace('[current]', asPath)
  const target = openInNewWindow ? '_blank' : ''

  return (
    openInNewWindow || isExternal
      ? (
        <a
          href={modifiedHref}
          target={target}
          rel="noreferrer"
          onClick={onClick}
          {...props}
        />
        )
      : (
        <NextLink href={modifiedHref} passHref onClick={onClick} {...props} />
        )
  )
}
